import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const store = ({ customerMasterInvoice }: Object) => customerMasterInvoice;
const configStore = ({ config }: Object) => config;

const makeSelectDocumentTypeOptions = () => createSelector(
  configStore,
  (state: Object) => R.pathOr([], ['invoice', 'options', 'availableDocumentTypes'], state),
);

const makeSelectInvoiceStatusOptions = () => createSelector(
  configStore,
  (state: Object) => R.pathOr([], ['invoice', 'options', 'customerInvoiceStatuses'], state),
);

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(
    R.prop('index'),
    R.values(itemList),
  ),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectMasterInvoiceConfigs = () => createSelector(
  store,
  ({ currentBranchConfigs }: Object) => {
    const autoNumber = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED,
      currentBranchConfigs,
    );

    const dateRange = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DATE_RANGE_DAYS,
      currentBranchConfigs,
      30,
    );

    const invoiceFilterDateRange = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS,
      currentBranchConfigs,
      30,
    );

    const defaultDocumentTypeGuids = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES,
      currentBranchConfigs,
      [],
    );

    const defaultInvoiceStatusGuids = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS,
      currentBranchConfigs,
      [],
    );

    const defaultInvoicePaymentTerms = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_INVOICE_PAYMENT_TERMS,
      currentBranchConfigs,
    );

    const groupByReference = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_GROUP_BY_REFERENCE,
      currentBranchConfigs,
    );

    const carrierDocumentTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      currentBranchConfigs,
      GC.CARRIER_DOCUMENT_TYPE,
    );

    const defaultGroupBy = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_GROUP_BY,
      currentBranchConfigs,
    );

    const defaultCarrierDocumentTypeGuids = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_CARRIER_DOCUMENT_TYPES,
      currentBranchConfigs,
      [],
    );

    const defaultTemplate = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_TEMPLATE,
      currentBranchConfigs,
    );

    const defaultFactoringName = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_FACTORING_NAME,
      currentBranchConfigs,
    );

    const defaultSendEmails = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_SEND_EMAILS,
      currentBranchConfigs,
    );

    const defaultReplyTo = G.getConfigValueFromStore(
      GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_REPLY_TO,
      currentBranchConfigs,
    );

    return {
      dateRange,
      autoNumber,
      defaultGroupBy,
      defaultReplyTo,
      defaultTemplate,
      groupByReference,
      defaultSendEmails,
      defaultFactoringName,
      invoiceFilterDateRange,
      defaultDocumentTypeGuids,
      defaultInvoiceStatusGuids,
      defaultInvoicePaymentTerms,
      carrierDocumentTypeOptions,
      defaultCarrierDocumentTypeGuids,
    };
  },
);

const makeSelectUpdatedInvoice = () => createSelector(
  store,
  ({ updatedInvoice }: Object) => updatedInvoice,
);

const makeSelectAvailableFactoringTypes = () => createSelector(
  store,
  ({ availableFactoringTypes }: Object) => availableFactoringTypes,
);

const makeSelectRecallDocumentsGuid = () => createSelector(
  store,
  ({ recallDocumentsGuid }: Object) => recallDocumentsGuid,
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectUpdatedInvoice,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectDocumentTypeOptions,
  makeSelectMasterInvoiceConfigs,
  makeSelectInvoiceStatusOptions,
  makeSelectRecallDocumentsGuid,
  makeSelectAvailableFactoringTypes,
};
