import styled from 'styled-components';
import RDatePicker from 'react-datepicker';
import { space, width, height, fontSize, minHeight, fontWeight, borderRadius } from 'styled-system';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { AbsoluteBox } from '../../../ui';
//////////////////////////////////////////////////

const renderBorderColor = ({ hasError }: Object) => {
  if (hasError) {
    return G.getTheme('forms.inputs.borderColorErr');
  }

  return G.getTheme('colors.dark.grey');
};

const LabelBox = styled(AbsoluteBox)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & .required::after {
    content: '*';
    color: ${() => G.getTheme('colors.light.mainRed')};
  }
`;

const Error = styled.span`
  ${width}

  font-size: 11px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: ${({ left }: Object) => left || '5px'};
  width: ${({ width }: Object) => width || '100%'};
  z-index: ${({ zIndex }: Object) => zIndex || 'unset'};
  top: ${({ top }: Object) => top || 'calc(100% + 1px)'};
  color: ${() => G.getTheme('forms.inputs.errorTextColor')};
`;

Error.defaultProps = {
  width: 'max-content',
};

const Input = styled.input`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${fontWeight}
  ${borderRadius}

  cursor: text;
  outline: none;
  line-height: 36px;
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
`;

Input.defaultProps = {
  pl: 15,
  pr: 25,
  height: 36,
  borderRadius: 4,
};

const MultiValuesInput = styled.input`
  ${space}
  ${width}
  ${height}

  border: none;
  outline: none;

  display: ${({ display }: Object) => display};
  min-width: ${({ minWidth }: Object) => minWidth};
  background: ${({ background }: Object) => background};

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

const Textarea = styled.textarea`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${minHeight}
  ${borderRadius}

  cursor: text;
  resize: none;
  outline: none;
  line-height: 20px;
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

Textarea.defaultProps = {
  height: 80,
  borderRadius: 4,
  padding: '2px 25px 2px 15px',
};

const SelectWrapper = styled.div`
  ${width}

  &:after {
    width: 4px;
    height: 4px;
    content: '';
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
    top: ${({ afterTop }: Object) => afterTop || '17px'};
    right: ${({ afterRight }: Object) => afterRight || '10px'};
  }
`;

const InputSelect = styled.select`
  ${space}
  ${width}
  ${borderRadius}

  height: 36px;
  outline: none;
  padding: 0 15px;
  appearance: none;
  line-height: 36px;
  position: relative;
  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getThemeByCond(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor')
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

InputSelect.defaultProps = {
  borderRadius: 4,
};

const Checkbox = styled.input`
  ${space}

  position: relative;

  &:focus {
    outline: none;
  }
  &:before {
    border: 1px solid ${() => G.getTheme('forms.checkboxes2.borderColor')};
    left: 0;
    top: 50%;
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 3px;
    position: absolute;
    transform: translate(0, -50%);
    background-color: ${() => G.getTheme('forms.checkboxes2.bgColor')};
  }
  &:checked:before {
    border: 1px solid ${() => G.getTheme('forms.checkboxes2.borderColor')};
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 3px;
    position: absolute;
    background-color: ${() => G.getTheme('forms.checkboxes2.bgColor')};
  }
  &:checked:after {
    border: solid ${() => G.getTheme('forms.checkboxes2.textColor')};
    top: 50%;
    left: 1px;
    width: 3px;
    height: 6px;
    content: '';
    position: absolute;
    border-width: 0px 3px 3px 0;
    transform: rotate(45deg) translate(0, -90%);
  }
`;

const CountrySelectWrapper = styled.div`
  ${space}
  ${width}

  position: relative;

  & > select {
    z-index: 0;
    width: 100%;
    height: 36px;
    outline: none;
    appearance: none;
    line-height: 36px;
    padding-left: 15px;
    border-radius: 4px;
    position: relative;
    padding-right: 25px;
    background-color: ${() => G.getTheme('forms.inputs.bgColor')};
    border: 1px solid ${(props: Object) => renderBorderColor(props)};

    &:focus {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
    }
  }

  &:after {
    top: 17px;
    width: 4px;
    content: '';
    right: 10px;
    height: 4px;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
  }
`;

const DatePicker = styled(RDatePicker)`
  ${space}
  ${width}

  height: 36px;
  cursor: text;
  outline: none;
  line-height: 36px;
  border-radius: 4px;
  padding-left: 15px;
  background-color: ${() => G.getTheme('inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

const CalendarWrapper = styled.div`
  ${space}
  ${width}

  & .react-datepicker-popper[data-placement^="top"] {
    margin-left: 10px;
  }
  & .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
  }
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker__navigation--previous {
    border-right-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__day--selected {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
    &:hover {
      background-color: ${() => G.getTheme('colors.light.mainRed')};
    }
  }
  & .react-datepicker {
    box-shadow: 0 0 1px 1px ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__header--time {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      padding: 0;
      height: 183px !important;
      & li.react-datepicker__time-list-item {
        padding: 5px 6px;
      }
      & li.react-datepicker__time-list-item--selected {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
      &:hover {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
        }
      }
    }
  & .react-datepicker__close-icon::after {
    right: 15px;
  }
  & .react-datepicker-popper {
    z-index: 1000;
  }
`;

const CalendarIconWrapper = styled(CalendarWrapper)`
  & .react-datepicker-popper[data-placement^="bottom-start"] {
    transform: translate3d(0px, 38px, 0px) !important;
  }
  & .react-datepicker-popper[data-placement^="top-start"] {
    transform: translate3d(-10px, -101%, 0px) !important;
  }
`;

const PhoneNumberInputWrapper = styled.div`
  ${space}
  ${width}

  & .react-phone-number-input {
    width: 100%;
  }
  & .react-phone-number-input__row {
    width: 100%;
  }
  & .react-phone-number-input__input--style {
    height: 36px;
    width: calc(100% - 30px);
    border: ${({ phoneInputBorder }: Object) => phoneInputBorder};
    padding-left: ${({ phoneInputPaddingLeft }: Object) => phoneInputPaddingLeft};
    border-radius: ${({ phoneInputBorderRadius }: Object) => phoneInputBorderRadius};
    border-color: ${(props: Object) => renderBorderColor(props)};
    &:focus {
      border-color: rgba(206, 40, 40, 0.5);
    }
  }
`;

export {
  Input,
  Error,
  Textarea,
  Checkbox,
  LabelBox,
  DatePicker,
  InputSelect,
  SelectWrapper,
  CalendarWrapper,
  MultiValuesInput,
  renderBorderColor,
  CalendarIconWrapper,
  CountrySelectWrapper,
  PhoneNumberInputWrapper,
};
