import React from 'react';
// forms
import { Fieldset2 } from '../../../forms';
// ui
import { Flex } from '../../../ui';
// feature load-board
import RateInfo from './rate-info';
import { fields } from '../settings/shipment-form';
//////////////////////////////////////////////////

const ShipmentForm = (props: Object) => {
  const {
    rateProps,
    handleChange,
  } = props;

  return (
    <Flex
      gap={20}
      alignItems='flex-start'
      height='calc(100% - 30px)'
    >
      <Fieldset2
        {...props}
        fields={fields}
        labelFontSize={12}
        onChange={handleChange}
        fieldsWrapperStyles={{ pt: 15, width: 220 }}
      />
      <RateInfo {...rateProps} />
    </Flex>
  );
};

export default ShipmentForm;
