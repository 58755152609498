import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
// feature template-inspection
import * as C from '../../constants';
import TrailerBack from '../assets/trailer-back';
import TrailerSide from '../assets/trailer-side';
import TrailerFront from '../assets/trailer-front';
import { RoofPartMarker } from '../assets/markers';
import TrailerInterior from '../assets/trailer-interior';
//////////////////////////////////////////////////

const TAP = R.prop('trailerComponentTypes', C);

// Trailer Front
const trailerFrontConfig = {
  size: [429, 655],
  image: TrailerFront,
  vehicleComponentTypes: {
    [TAP.PASSENGER_SIDE_TOP_CORNER]: {
      position: ['0%', '-1%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-top-corner',
        'Passenger side top corner'),
    },
    [TAP.DRIVER_SIDE_TOP_CORNER]: {
      position: ['92.5%', '-1%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-top-corner',
        'Driver side top corner'),
    },
    [TAP.REGISTRATION_BOX]: {
      position: ['82%', '55%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-registration-box',
        'Registration box'),
    },
    [TAP.SEVEN_WAY_GLAD_HANDS]: {
      position: ['75.5%', '63%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-seven-way-glad-hands',
        'Seven way glad hands'),
    },
    [TAP.K_BRACE]: {
      position: ['46%', '71%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-k-brace',
        'K brace'),
    },
    [TAP.PASSENGER_SIDE_LANDING_GEAR_LEG]: {
      position: ['15%', '85%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-landing-gear-leg',
        'Passenger side landing gear leg'),
    },
    [TAP.DRIVER_SIDE_LANDING_GEAR_LEG]: {
      position: ['76%', '85%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-landing-gear-leg',
        'Driver side landing gear leg'),
    },
  },
};

// Trailer Back
const trailerBackConfig = {
  size: [422, 650],
  image: TrailerBack,
  imageStyles: { mt: 20 },
  vehicleComponentTypes: {
    [TAP.REAR_DRIVER_SIDE_CLEARANCE_LIGHT]: {
      position: ['6%', '-4.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-clearance-light',
        'Rear driver side clearance light',
      ),
    },
    [TAP.REAR_MIDDLE_LEFT_CLEARANCE_LIGHT]: {
      position: ['31.5%', '-4.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-middle-left-clearance-light',
        'Rear middle left clearance light',
      ),
    },
    [TAP.REAR_MIDDLE_CENTRAL_CLEARANCE_LIGHT]: {
      position: ['45.5%', '-4.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-middle-central-clearance-light',
        'Rear middle central clearance light',
      ),
    },
    [TAP.REAR_MIDDLE_RIGHT_CLEARANCE_LIGHT]: {
      position: ['62%', '-4.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-middle-right-clearance-light',
        'Rear middle right clearance light',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_CLEARANCE_LIGHT]: {
      position: ['86%', '-4.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-clearance-light',
        'Rear passenger side clearance light',
      ),
    },
    [TAP.DRIVER_SIDE_DOOR_HINGES]: {
      position: ['0%', '34%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-door-hinges',
        'Driver side door hinges',
      ),
    },
    [TAP.PASSENGER_SIDE_DOOR_HINGES]: {
      position: ['91.5%', '34%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-door-hinges',
        'Passenger side door hinges',
      ),
    },
    [TAP.DOOR_HANDLES]: {
      position: ['35%', '55%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-door-handles',
        'Door handles',
      ),
    },
    [TAP.REAR_SIDE_REFLECTIVE_TAPE]: {
      position: ['72%', '62.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-side-reflective-tape',
        'Rear side reflective tape',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_TURN_SIGNAL]: {
      position: ['6%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-turn-signal',
        'Rear driver side turn signal',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_TURN_SIGNAL]: {
      position: ['86%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-turn-signal',
        'Rear passenger side turn signal',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_LOWER_CORNER]: {
      position: ['-4%', '70%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-lower-corner',
        'Rear driver side lower corner',
      ),
    },
    [TAP.DRIVER_SIDE_BRAKE_TAIL_LIGHT]: {
      position: ['20%', '71.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-brake-tail-light',
        'Driver side brake tail light',
      ),
    },
    [TAP.PASSENGER_SIDE_BRAKE_TAIL_LIGHT]: {
      position: ['73%', '71.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-brake-tail-light',
        'Passenger side brake tail light',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_LOWER_CORNER]: {
      position: ['96%', '70%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-lower-corner',
        'Rear passenger side lower corner',
      ),
    },
    [TAP.REAR_LICENSE_PLATE_AND_LICENSE_PLATE_LIGHT]: {
      position: ['6.5%', '76.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-license-plate-and-license-plate-light',
        'Rear license plate and license plate light',
      ),
    },
    [TAP.BUMPER]: {
      position: ['46%', '81%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-bumper',
        'Bumper',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_MUDFLAP]: {
      position: ['11%', '90%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-mudflap',
        'Rear driver side mudflap',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_MUDFLAP]: {
      position: ['82%', '90%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-mudflap',
        'Rear passenger side mudflap',
      ),
    },
  },
};

// Trailer Interior
const trailerInteriorConfig = {
  size: [591, 470],
  image: TrailerInterior,
  vehicleComponentTypes: {
    [TAP.INNER_ROOF]: {
      position: ['47%', '8%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-inner-roof',
        'Inner roof',
      ),
    },
    [TAP.DRIVER_SIDE_INNER_DOOR]: {
      position: ['9%', '32%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-inner-door',
        'Driver side inner door',
      ),
    },
    [TAP.DRIVER_SIDE_INNER_PANEL]: {
      position: ['29%', '32%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-inner-panel',
        'Driver side inner panel',
      ),
    },
    [TAP.REAR_INNER_PANEL]: {
      position: ['46.5%', '32%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-inner-panel',
        'Rear inner panel',
      ),
    },
    [TAP.PASSENGER_SIDE_INNER_PANEL]: {
      position: ['64.5%', '32%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-inner-panel',
        'Passenger side inner panel',
      ),
    },
    [TAP.PASSENGER_SIDE_INNER_DOOR]: {
      position: ['85.5%', '32%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-inner-door',
        'Passenger side inner door',
      ),
    },
    [TAP.TRAILER_FLOOR_BED]: {
      position: ['46.5%', '55%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-trailer-floor-bed',
        'Trailer floor bed',
      ),
    },
  },
};

// Trailer Right
const trailerRightConfig = {
  maxHeight: 205,
  size: [689, 194],
  image: TrailerSide,
  directionColumn: true,
  imageStyles: { mb: 10 },
  vehicleComponentTypes: {
    [TAP.REAR_PASSENGER_SIDE_PANEL]: {
      position: ['22.5%', '23%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-panel',
        'Rear passenger side panel',
      ),
    },
    [TAP.FRONT_PASSENGER_SIDE_PANEL]: {
      position: ['71.25%', '23%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-panel',
        'Front passenger side panel',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_TIRES_HUBCAPS]: {
      position: ['4%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-tires-hubcaps',
        'Rear passenger side tires hubcaps',
      ),
    },
    [TAP.FRONT_PASSENGER_SIDE_TIRES_HUBCAPS]: {
      position: ['10%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-tires-hubcaps',
        'Front passenger side tires hubcaps',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_WHEELS_AND_RIMS]: {
      position: ['-2%', '78%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-wheels-and-rims',
        'Rear passenger side wheels and rims',
      ),
    },
    [TAP.FRONT_PASSENGER_SIDE_WHEELS_AND_RIMS]: {
      position: ['16%', '78%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-wheels-and-rims',
        'Front passenger side wheels and rims',
      ),
    },
    [TAP.REAR_PASSENGER_SIDE_TIRES]: {
      position: ['2%', '91%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-tires',
        'Rear passenger side tires',
      ),
    },
    [TAP.FRONT_PASSENGER_SIDE_TIRES]: {
      position: ['12%', '91%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-tires',
        'Front passenger side tires',
      ),
    },
  },
};

// Trailer Left
const trailerLeftConfig = {
  maxHeight: 165,
  flipImage: true,
  size: [689, 194],
  image: TrailerSide,
  directionColumn: true,
  marker: RoofPartMarker,
  markerStyles: { top: '-15%', left: '2%' },
  inspectionMarkerStyles: { top: '-15%', left: '8%', transform: 'scale(1.4)' },
  vehicleComponentTypes: {
    [TAP.ROOF]: {
      position: ['34%', '-24%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-roof',
        'Roof',
      ),
    },
    [TAP.FRONT_DRIVER_SIDE_PANEL]: {
      position: ['71.25%', '23%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-panel',
        'Front driver side panel',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_PANEL]: {
      position: ['22.5%', '23%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-panel',
        'Rear driver side panel',
      ),
    },
    [TAP.CRANK_HANDLE]: {
      position: ['76%', '73%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-crank-handle',
        'Crank handle',
      ),
    },
    [TAP.BELLY_AIR_LINES]: {
      position: ['45%', '72%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-belly-air-lines',
        'Belly air lines',
      ),
    },
    [TAP.FRONT_DRIVER_SIDE_TIRES_HUBCAPS]: {
      position: ['10%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-tires-hubcaps',
        'Front driver side tires hubcaps',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_TIRES_HUBCAPS]: {
      position: ['4%', '67%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-tires-hubcaps',
        'Rear driver side tires hubcaps',
      ),
    },
    [TAP.FRONT_DRIVER_SIDE_WHEELS_AND_RIMS]: {
      position: ['16%', '78%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-wheels-and-rims',
        'Front driver side wheels and rims',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_WHEELS_AND_RIMS]: {
      position: ['-2%', '78%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-wheels-and-rims',
        'Rear driver side wheels and rims',
      ),
    },
    [TAP.FRONT_DRIVER_SIDE_TIRES]: {
      position: ['12%', '91%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-tires',
        'Front driver side tires',
      ),
    },
    [TAP.REAR_DRIVER_SIDE_TIRES]: {
      position: ['2%', '91%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-tires',
        'Rear driver side tires',
      ),
    },
  },
};

const trailerConfigs = {
  [C.INSPECTION_TRAILER_BACK]: trailerBackConfig,
  [C.INSPECTION_TRAILER_LEFT]: trailerLeftConfig,
  [C.INSPECTION_TRAILER_FRONT]: trailerFrontConfig,
  [C.INSPECTION_TRAILER_RIGHT]: trailerRightConfig,
  [C.INSPECTION_TRAILER_INTERIOR]: trailerInteriorConfig,
};

export default trailerConfigs;
