import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms';
// feature master-invoice
import { getFields, defaultValues, getValidationSchema } from '../settings/print-formik-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
    validationSchema: ({ templateOptions }: Object) => Yup.lazy((values: Object) => (
      Yup.object().shape(getValidationSchema(values, templateOptions))
    )),
    handleSubmit: (values: Object, { props }: Object) => {
      const { sendEmails } = values;
      const { submitAction } = props;

      const fieldsToOmit = sendEmails ?
        [GC.FIELD_SEND_EMAILS] :
        [GC.FIELD_EMAILS, GC.FIELD_SEND_EMAILS, GC.FIELD_MAIL_SENDING_REPLY_TO];

      submitAction(R.omit(fieldsToOmit, values));
    },
    displayName: 'PRINT_OPTIONS_FORM',
  }),
  pure,
);

const PrintOptionsForm = (props: Object) => {
  const {
    handleSubmit,
    templateOptions,
    useVendorDocuments,
    documentTypeOptions,
    vendorDocumentTypeOptions,
  } = props;

  const optionsForSelect = { templateOptions, documentTypeOptions, vendorDocumentTypeOptions };

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={getFields(useVendorDocuments)}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(PrintOptionsForm);
