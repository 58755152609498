import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// feature load-board
import { getUOMs } from '../settings/options';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.titleGrey');

const renderDataRow = ({ mb, width, label, value, textFontWeight, displayedValue }: Object) => {
  if (G.isNilOrEmpty(value)) return null;

  return (
    <Flex mb={mb} justifyContent='space-between' width={R.or(width, 105)}>
      {
        G.isNotNilAndNotEmpty(label) &&
        <Box mr='8px' fontWeight={500} color={greyColor}>{label}:</Box>
      }
      <TextComponent
        withEllipsis={true}
        title={displayedValue}
        fontWeight={textFontWeight}
        maxWidth={G.ifElse(G.isNilOrEmpty(label), '100%', '75%')}
      >
        {displayedValue}
      </TextComponent>
    </Flex>
  );
};

const RateInfo = (props: Object) => {
  const {
    valuesRate,
    valuesRateCurrency,
    rate: {
      rate,
      rateCurrency,
      negotiatedRate,
      negotiatedRateCurrency,
    },
    dimensions: {
      length,
      weight,
      lengthUom,
      weightUom,
    },
    distanceInfo: {
      distance,
      distanceUom,
      originDeadhead,
      originDeadheadUom,
      destinationDeadhead,
      destinationDeadheadUom,
    },
  } = props;

  const showDistance = R.gt(distance, 0);
  const rateSymbol = G.getCurrencySymbol(rateCurrency);

  const totalDistance = G.ifElse(
    showDistance,
    R.add(distance, R.or(originDeadhead, 0)),
    null,
  );

  let rateCpm = null;
  let cpmValue = null;
  let cpmCurrency = null;

  if (showDistance) {
    if (G.isNotNilAndNotEmpty(valuesRate)) {
      cpmValue = G.toFixed(R.divide(valuesRate, totalDistance));
      cpmCurrency = valuesRateCurrency;
    } else if (G.isNotNilAndNotEmpty(negotiatedRate)) {
      cpmValue = G.toFixed(R.divide(negotiatedRate, totalDistance));
      cpmCurrency = negotiatedRateCurrency;
    }

    if (G.isNotNilAndNotEmpty(rate)) {
      rateCpm = G.toFixed(R.divide(rate, totalDistance));
    }
  }

  return (
    <Flex
      pl={10}
      gap='5px'
      width={240}
      fontSize={12}
      alignItems='normal'
      flexDirection='column'
    >
      <Flex gap={20} justifyContent='space-between'>
        {renderDataRow({
          value: length,
          displayedValue: `${length} ${getUOMs(lengthUom)}`,
          label: G.getWindowLocale('titles:length', 'Length'),
        })}
        {renderDataRow({
          value: weight,
          displayedValue: `${weight} ${getUOMs(weightUom)}`,
          label: G.getWindowLocale('titles:weight', 'Weight'),
        })}
      </Flex>
      <Flex gap={20} justifyContent='space-between'>
        {renderDataRow({
          value: originDeadhead,
          label: G.getWindowLocale('titles:origin-deadhead', 'DH-O'),
          displayedValue: `${originDeadhead} ${getUOMs(originDeadheadUom)}`,
        })}
        {renderDataRow({
          value: destinationDeadhead,
          label: G.getWindowLocale('titles:destination-deadhead', 'DH-D'),
          displayedValue: `${destinationDeadhead} ${getUOMs(destinationDeadheadUom)}`,
        })}
      </Flex>
      {
        showDistance &&
        <Flex mb={15} gap={20} justifyContent='space-between'>
          {renderDataRow({
            value: distance,
            label: G.getWindowLocale('titles:trip', 'Trip'),
            displayedValue: `${distance} ${getUOMs(distanceUom)}`,
          })}
          {renderDataRow({
            value: totalDistance,
            label: G.getWindowLocale('titles:total', 'Total'),
            displayedValue: `${totalDistance} ${getUOMs(distanceUom)}`,
          })}
        </Flex>
      }
      {renderDataRow({
        value: rate,
        width: '100%',
        textFontWeight: 'bold',
        label: G.getWindowLocale('titles:pay-rate', 'Pay Rate'),
        displayedValue: `${rateSymbol} ${G.toFixed(rate)} (${rateSymbol} ${rateCpm})`,
      })}
      {renderDataRow({
        width: '100%',
        value: cpmValue,
        textFontWeight: 'bold',
        label: G.getWindowLocale('titles:cpm', 'CPM'),
        displayedValue: `${G.getCurrencySymbol(cpmCurrency)} ${cpmValue}`,
      })}
    </Flex>
  );
};

export default RateInfo;
