import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setBranchInfo = createAction('setBranchInfo');
export const toggleHotOrder = createAction('toggleHotOrder');
export const setDivisionGuid = createAction('setDivisionGuid');
export const cleanNewDOStore = createAction('cleanNewDOStore');
export const setBranchBillTo = createAction('setBranchBillTo');
export const setBranchShipTo = createAction('setBranchShipTo');
export const setValueToStore = createAction('setValueToStore');
export const setActiveLeftTab = createAction('setActiveLeftTab');
export const setNumberOfLoads = createAction('setNumberOfLoads');
export const setEdiFileToStore = createAction('setEdiFileToStore');
export const setActiveRightTab = createAction('setActiveRightTab');
export const setBranchShipFrom = createAction('setBranchShipFrom');
export const setReorderedStops = createAction('setReorderedStops');
export const setFormDataToStop = createAction('setFormDataToStop');
export const addNewStopToStore = createAction('addNewStopToStore');
export const setIgnoreWarnings = createAction('setIgnoreWarnings');
export const saveBillToRequest = createAction('saveBillToRequest');
export const setPageTypeToStore = createAction('setPageTypeToStore');
export const setFormDataToStore = createAction('setFormDataToStore');
export const setRatePreviewFiles = createAction('setRatePreviewFiles');
export const removeItemFromStore = createAction('removeItemFromStore');
export const setValidationErrors = createAction('setValidationErrors');
export const removeStopFromStore = createAction('removeStopFromStore');
export const setPrimaryReference = createAction('setPrimaryReference');
export const setDriverRateToStore = createAction('setDriverRateToStore');
export const setBranchDefaultItem = createAction('setBranchDefaultItem');
export const getBranchDataRequest = createAction('getBranchDataRequest');
export const getBranchListRequest = createAction('getBranchListRequest');
export const getBranchListSuccess = createAction('getBranchListSuccess');
export const sendDataToApiRequest = createAction('sendDataToApiRequest');
export const setCustomerLoadNumber = createAction('setCustomerLoadNumber');
export const setInitialStateToStore = createAction('setInitialStateToStore');
export const removeValidationErrors = createAction('removeValidationErrors');
export const setCustomerRateToStore = createAction('setCustomerRateToStore');
export const saveAndDuplicateSuccess = createAction('saveAndDuplicateSuccess');
export const getBranchConfigsRequest = createAction('getBranchConfigsRequest');
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
export const removeValidationWarnings = createAction('removeValidationWarnings');
export const applyOrderRateUpliftRequest = createAction('applyOrderRateUpliftRequest');
export const getPrimaryRefSequenceRequest = createAction('getPrimaryRefSequenceRequest');
export const getPrimaryRefSequenceSuccess = createAction('getPrimaryRefSequenceSuccess');
export const setCustomerRateChargesToStore = createAction('setCustomerRateChargesToStore');
export const getAllAvBranchRefTypesRequest = createAction('getAllAvBranchRefTypesRequest');
export const getAllAvBranchRefTypesSuccess = createAction('getAllAvBranchRefTypesSuccess');
export const setValidationErrorsAndWarnings = createAction('setValidationErrorsAndWarnings');
export const recalculateLoadDistancesRequest = createAction('recalculateLoadDistancesRequest');
export const recalculateLoadDistancesSuccess = createAction('recalculateLoadDistancesSuccess');
export const getBranchSharedAccessorialListSuccess = createAction('getBranchSharedAccessorialListSuccess');
// template
export const saveAsTemplateRequest = createAction('saveAsTemplateRequest');
export const getTemplateDataRequest = createAction('getTemplateDataRequest');
export const copyTemplateDataToStore = createAction('copyTemplateDataToStore');
export const getBranchAndTemplateDataRequest = createAction('getBranchAndTemplateDataRequest');
export const getBranchConfigsWithTemplateRequest = createAction('getBranchConfigsWithTemplateRequest');
export const getBranchConfigsWithTemplateSuccess = createAction('getBranchConfigsWithTemplateSuccess');
// duplicate order
export const getOrderRequest = createAction('getOrderRequest');
export const getBranchConfigsOnDuplicateDOSuccess = createAction('getBranchConfigsOnDuplicateDOSuccess');
export const getBranchConfigsWithDuplicateDORequest = createAction('getBranchConfigsWithDuplicateDORequest');
// ai recognize
export const aiRecognizeRequest = createAction('aiRecognizeRequest');
export const aiRecognizeSuccess = createAction('aiRecognizeSuccess');
export const fetchStopLatLonRequest = createAction('fetchStopLatLonRequest');
export const fetchStopLatLonSuccess = createAction('fetchStopLatLonSuccess');
export const fetchStopLatLonFailure = createAction('fetchStopLatLonFailure');
