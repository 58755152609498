import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withProps,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { openModal, closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
// features
import { setExpandedContainerOptions } from '../features/expanded-container/actions';
import { makeSelectExpandedContainerOptions } from '../features/expanded-container/selectors';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import routesMap from '../utilities/routes';
//////////////////////////////////////////////////

export * from './common';

export { withVinLookup } from './with-vin-lookup';
export { withDraggableHOC } from './with-draggable';
export { withWindowSize } from './with-window-size';
export { withAsyncReport } from './with-async-report';
export { withDataFilterHoc } from './with-data-filter';
export { withShowListIssues } from './with-list-issues';
export { withFilteredList } from './with-filtered-list';
export { withAsyncRequest } from './with-async-request';
export { withFixedPopover } from './with-fixed-popover';
export { withAsyncRefTypes } from './with-async-ref-types';
export { withReferenceTypes } from './with-reference-types';
export { withAsyncPinReport } from './with-async-pin-report';
export { withAsyncTelEvents } from './with-async-tel-events';
export { withAsyncCloEvents } from './with-async-clo-events';
export { withComponentHeight } from './with-component-height';
export { withLoadFilePreview } from './with-load-file-preview';
export { withFilterTableList } from './with-filter-table-list';
export { withRerenderComponent } from './with-rerender-component';
export { withAsyncAccessorials } from './with-async-accessorials';
export { withReasonCodeConfigs } from './with-reason-code-configs';
export { withAsyncDistanceCalc } from './with-async-distance-calc';
export { withAsyncInvoicesCount } from './with-async-invoices-count';
export { withAsyncStatusMessages } from './with-async-status-messages';
export { withAsyncGeoFencingZones } from './with-async-geo-fencing-zones';
export { withAsyncRolesByUserType } from './with-async-roles-by-user-type';
export { withAsyncLoadDivisionGuid } from './with-async-load-division-guid';
export { withAsyncSequence } from './with-async-sequence';
export { withAsyncSharedAccessorials } from './with-async-shared-accessorials';
export { withAsyncConfigs, withAsyncConfigsNamed } from './with-async-configs';
export { withAsyncInvoiceStatusConfigs } from './with-async-invoice-status-configs';
export { withAsyncMarginViolationRules } from './with-async-margin-violation-rules';
export { withAsyncBranchConfigsByNames } from './with-async-branch-configs-by-names';
export { withAsyncAvailableDocumentTypes } from './with-async-available-document-types';
export { withAsyncSearchTemplateAutocomplete } from './with-async-search-template-autocomplete';
export { withAsyncInitialData, withAsyncInitialDataOnDidMount } from './with-async-initial-data';
export { withAsyncMailSendingIntegrationExists } from './with-async-mail-sending-integration-exists';
export { withAsyncLoadReferences, withAsyncCloReferenceByEventGuids } from './with-async-load-references';
export { withAsyncDocumentTemplates, withAsyncDocumentTemplatesByTelGuid } from './with-async-document-templates';
export {
  withGoogleAutocomplete,
  enhanceLocationWithGoogleAutocomplete,
} from './with-google-autocomplete';
export {
  withMassEntitiesRemove,
  emptyMassRemoveListMsg,
  getMassRemoveListConfirmation,
} from './with-mass-entities-remove';
export {
  withAsyncCLODocuments,
  withAsyncTelDocuments,
  withAsyncGetVendorDocumentsByCloGuid,
} from './with-async-documents';
export {
  getInvoiceGlCode,
  getInvoiceGlCode2,
  withAsyncGetRoles,
  withAsyncGetServiceVendor,
  withAsyncGetCarrierNameMap,
  withAsyncGetBranchListByType,
  withAsyncGetTrailersInService,
  withAsyncGetDetailsForPostLoad,
  withAsyncGetDriverListFullName,
  withAsyncGetDispatchingGroupList,
  withAsyncGetFactoringPaymentTerm,
  withAsyncGetGLCodeMappingsByScope,
  withAsyncGetRoleGrantedBranchGuids,
  withAsyncGetUnitIdListByEntityType,
  withAsyncGetSelectedRateByLoadGuid,
  withAsyncGetAvailableFactoringTypes,
  withAsyncGetUserGeneralListFullName,
  withAsyncGetDriverListWithAssignment,
  withAsyncTruckListChildrenAndCurrent,
  withAsyncGetBranchChildrenWithShared,
  withAsyncGetServiceVendorListAvailable,
  withAsyncGetFleetVendorCompanyNameList,
  withAsyncGetTransportationModeGrouping,
  withAsyncGetDefaultCarrierAccessorials,
  withAsyncGetCommissionAssigneeListByLoadType,
  withAsyncGetUserGeneralListFullNameOnDidMount,
} from './with-async-get-endpoint-hocs';
//////////////////////////////////////////////////

const handleToggleStatus = (event: Event, setStatus: Function) => {
  G.stopPropagation(event);

  setStatus((prev: boolean) => R.not(prev));
};

export const withFormGroupOpenedStatus = compose(
  withState('isFormGroupOpened', 'setStatus', true),
  withHandlers({
    handleToggleFormGroupState: ({ setStatus }: Object) => (event: Object) => handleToggleStatus(event, setStatus),
  }),
);

export const withCheckboxState = ({ name, callbackName }: Object) => compose(
  withState(name, 'setCheckboxState', false),
  withHandlers({
    setCheckboxState: (props: Object) => (event: Event) => {
      const { setCheckboxState } = props;

      const handler = R.path([callbackName], props);

      G.stopPropagation(event);

      setCheckboxState((prev: boolean) => R.not(prev));

      if (G.isFunction(handler)) return handler(props);
    },
  }),
  pure,
);

export const hideIf = compose(
  withProps((props: Object) => ({ hide: props.hideComponent })),
  branch(
    ({ hide }: Object) => G.isTrue(hide),
    renderNothing,
  ),
);

export const withActiveStatus = compose(
  withState('isActiveStatus', 'setStatus', true),
  withHandlers({
    handleSetStatus: ({ setStatus }: Object) => (open: boolean = false) => setStatus(open),
    handleToggleActiveState: ({ setStatus }: Object) => (event: Object) => handleToggleStatus(event, setStatus),
  }),
);

export const withBooleanStatus = (initial: boolean) => compose(
  withState('booleanStatus', 'setBooleanStatus', initial),
  withHandlers({
    handleToggleBooleanStatus: ({ setBooleanStatus }: Object) => (event: Object) => handleToggleStatus(event, setBooleanStatus),
  }),
);

export const withIsOpenedModalStatus = compose(
  withState('isModalOpened', 'setStatus', false),
  withHandlers({
    toggleIsModalOpenedStatus: ({ setStatus }: Object) => (event: Object) => handleToggleStatus(event, setStatus),
  }),
);

export const withChangeZIndex = compose(
  withState('changedZIndex', 'setChangedZIndex', (props: Object) => R.pathOr(10, ['zIndex'], props)),
  withHandlers({
    handleChangeZIndex: ({ setChangedZIndex }: Object) => () => setChangedZIndex(1000),
    handleReturnZIndex: ({ zIndex, setChangedZIndex }: Object) => () => setChangedZIndex(R.or(zIndex, 10)),
  }),
);

export const withDnDStartEnd = R.compose(
  withState('dndProcessing', 'setDnDProcessing', false),
  withHandlers({
    handleDragStart: (props: Object) => () => {
      const { setDnDProcessing } = props;
      setDnDProcessing(true);
    },
    handleDragEnd: (props: Object) => () => {
      const { setDnDProcessing } = props;
      setDnDProcessing(false);
    },
  }),
);

export const withHoveredZIndex = ({ zIndex }: Object) => compose(
  withState('hoveredZIndex', 'setHoveredZIndex', null),
  withHandlers({
    handleZIndexOnHover: ({ setHoveredZIndex }: Object) => () => setHoveredZIndex(zIndex),
    handleZIndexOnUnHover: ({ setHoveredZIndex }: Object) => () => setHoveredZIndex(null),
  }),
);

export const withComponentDidMountCallback = ({ callbackName }: Object) => compose(
  lifecycle({
    componentDidMount() {
      const props = this.props;
      const callback = R.path([callbackName], props);
      G.callFunction(callback);
    },
  }),
  pure,
);

export const withConnectModalActions = compose(
  connect(null, { openModal, closeModal }),
);

export const withConnectModalAndLoaderActions = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
);

const expandedContainerMapStateToProps = (state: Object) => createStructuredSelector({
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

export const withSetExpandedContainerOptions = compose(
  connect(expandedContainerMapStateToProps, { setExpandedContainerOptions }),
  withHandlers({
    handleSetExpandedContainerOptions: ({ setExpandedContainerOptions }: Object) => (options: Object) => {
      const {
        carrierName,
        componentType,
        visitPageGuid,
        parentPageGuid,
        openContainerAsNewWindow,
      } = options;

      const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);

      if (R.and(
        G.isFalse(openLoadInSidebar),
        R.includes(componentType, [GC.PAGE_DISPATCH_DETAILS_NEW_LOAD, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER]),
      )) {
        const route = G.getLoadRouteByConfigAndLoadType(
          routesMap,
          visitPageGuid,
          R.equals(componentType, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER),
        );

        if (G.isTrue(openContainerAsNewWindow)) {
          return window.open(
            `${window.location.origin}${route}`,
            'Details',
            'width:200,height:200',
          );
        }

        return G.goToLoadDetailsByConfigAndLoadType(routesMap, visitPageGuid);
      }

      return setExpandedContainerOptions({
        opened: true,
        componentType,
        visitPageGuid,
        options: {
          carrierName,
          [GC.FIELD_GUID]: parentPageGuid,
        },
      });
    },
  }),
);
