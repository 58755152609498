import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex } from '../../../ui';
// component map
import { Text, FlexWrapper, StopInfoContainer, StopMarkerWrapper } from '../ui';
//////////////////////////////////////////////////

const renderItemInfoPair = (firstInfo: string, secondInfo: string) => {
  if (G.isAnyNilOrEmpty([firstInfo, secondInfo])) return '';

  return `${firstInfo} ${secondInfo}`;
};

const renderItemInfo = (item: Object = {}) => {
  const { weight, volume, quantity, volumeUOM, weightType, packageType, itemInternalId } = item;

  const volumeInfo = renderItemInfoPair(volume, G.getUomLocale(volumeUOM));
  const weightInfo = renderItemInfoPair(weight, G.getUomLocale(weightType));
  const quantityInfo = renderItemInfoPair(quantity, G.getEnumLocale(packageType));

  if (G.isAllNilOrEmpty([volumeInfo, weightInfo, quantityInfo])) return itemInternalId;

  return G.createStringFromArray([weightInfo, quantityInfo, volumeInfo], ', ');
};

export const StopInfo = (props: Object) => {
  const { items, title, pickup, location, eventEarlyDate } = props;

  const icon = G.ifElse(
    G.isTrue(pickup),
    I.pickupStopIcon,
    I.dropStopIcon,
  )(G.getTheme('colors.light.lightGrey'), 11, 11);

  return (
    <StopInfoContainer>
      <Flex>{icon} <Text ml='3px'>{title}</Text></Flex>
      { G.isNotNilAndNotEmpty(eventEarlyDate) && (
        <FlexWrapper my='2px'>
          <Text mr='5px'>{I.calendar()} {G.getWindowLocale('date-early', 'Date Early')}:</Text>
          {G.convertDateTimeToConfigFormat(eventEarlyDate)}
        </FlexWrapper>
      )}
      <Text my='2px'>{I.locationMarker()} {G.concatLocationFields(location)}</Text>
      {R.gt(R.length(items), 1) && <Text>{G.getStopItemsInfo(items)}</Text>}
      {R.equals(R.length(items), 1) && <Text>{renderItemInfo(R.head(items))}</Text>}
    </StopInfoContainer>
  );
};

export const StopMarker = (props: Object) => (
  <StopMarkerWrapper>
    {`${R.path(['title', 0], props)}${G.getNumberFromString(props.title)}`}
  </StopMarkerWrapper>
);
