import * as R from 'ramda';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, FlexHovered, IconWrapper } from '../../../ui';
// groupable-table
import { FlexContainer } from '../ui';
import { renderTableRowCells } from './table-body-cell';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');
const bgLightBlue = G.getTheme('colors.bgLightBlue');
const lightGrey = G.getTheme('colors.light.lightGrey');

const ToggleComponent = ({ expanded, handleToggleExpandable }: Object) => {
  const title = G.ifElse(
    expanded,
    G.getWindowLocale('actions:collapse-all', 'Collapse All'),
    G.getWindowLocale('actions:expand-all', 'Expand All'),
  );

  return (
    <FlexContainer
      p='8px'
      height='100%'
      title={title}
      justify='center'
      direction='column'
    >
      <IconWrapper px={4} cursor='pointer' onClick={() => handleToggleExpandable()}>
        {expanded && I.arrowDownSimple(iconColor, 12, 12)}
        {R.not(expanded) && I.nextShape(iconColor, 12, 12)}
      </IconWrapper>
    </FlexContainer>
  );
};

export const GroupRow = (props: Object) => {
  const {
    data,
    style,
    rowIndent,
    callbackData,
    groupColumns,
    tableSettings,
  } = props;

  const innerData = {
    callbackData,
    tableSettings,
    withResizableColumns: true,
    columnSettings: groupColumns,
    resizeByGuid: props.resizeByGuid,
    fields: tableSettings.report.fields,
  };

  return (
    <FlexHovered
      style={style}
      pl={rowIndent}
      minWidth='100%'
      bgColor={lightGrey}
      transition='all 0.3s'
      justifyContent='flex-start'
    >
      {renderTableRowCells({ ...innerData, data })}
    </FlexHovered>
  );
};

export const GroupParentRow = (props: Object) => {
  const {
    data,
    style,
    expandedData,
    parentColumn,
    setExpandedData,
  } = props;

  const parentName = R.prop(GC.FIELD_NAME, data);
  const expanded = R.includes(parentName, expandedData);

  const { customComponent } = parentColumn;

  const handleToggleExpandable = useCallback(() => {
    if (R.not(expanded)) setExpandedData(R.append(parentName, expandedData));

    if (expanded) setExpandedData(R.filter(G.notEquals(parentName), expandedData));
  }, [expanded, expandedData, parentName]);


  return (
    <Flex
      style={style}
      minWidth='100%'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'
    >
      <FlexHovered
        p='8px 0'
        minWidth='100%'
        bgColor={lightGrey}
        transition='all 0.3s'
        justifyContent='flex-start'
        backgroundColor={bgLightBlue}
      >
        <ToggleComponent expanded={expanded} handleToggleExpandable={handleToggleExpandable} />
        <Box>{customComponent({ data })}</Box>
      </FlexHovered>
    </Flex>
  );
};

