import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
///////////////////////////////////////////////////////

const hasReasonCodes = ({ index, values, statusMessagesConfigs }: Object) => {
  const statusCode = R.pathOr('', ['statusMessages', index, GC.FIELD_STATUS_MESSAGE_CODE], values);
  const statusMessagesWithStatusReasonCodes = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID)),
    R.filter(R.prop(GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED)),
  )(statusMessagesConfigs);
  const reasonCodes = R.path([statusCode, 'reasonCodes'], statusMessagesWithStatusReasonCodes);

  return G.isNotNilAndNotEmpty(reasonCodes);
};

export const statusMessageFieldSettings = (index: number, { values, statusMessagesConfigs }: Object) => [
  {
    width: 400,
    type: 'select',
    isRequired: true,
    options: 'eventOptions',
    shouldCustomChange: true,
    label: G.getWindowLocale('titles:stop', 'Stop'),
    inputWrapperStyles: { width: 400, m: '20px 20px 0 0' },
    fieldName: `statusMessages.${index}.${GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID}`,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { isMapOpened, handleChange, handleReRenderMap } = props;

      if (isMapOpened) handleReRenderMap();

      handleChange(event);
    },
  },
  {
    width: 220,
    isRequired: true,
    type: 'datePicker',
    timeSelection: true,
    shouldCustomChange: true,
    zIndex: R.subtract(100, index),
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: G.getWindowLocale('titles:date', 'Date'),
    inputWrapperStyles: { width: 220, m: '20px 20px 0 0' },
    fieldName: `statusMessages.${index}.${GC.FIELD_STATUS_MESSAGE_DATE}`,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { isMapOpened, handleChange, handleReRenderMap } = props;

      if (isMapOpened) handleReRenderMap();

      handleChange(event);
    },
  },
  {
    width: 220,
    type: 'select',
    shouldCustomChange: true,
    options: 'statusCodeOptions',
    inputWrapperStyles: { width: 220, m: '20px 0 0 0' },
    label: G.getWindowLocale('titles:status-code', 'Status Code'),
    fieldName: `statusMessages.${index}.${GC.FIELD_STATUS_MESSAGE_CODE}`,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { isMapOpened, handleChange, handleReRenderMap } = props;

      if (isMapOpened) handleReRenderMap();

      handleChange(event);
    },
  },
  {
    width: 220,
    type: 'select',
    options: 'statusReasonCodeOptions',
    fieldName: `statusMessages.${index}.statusReasonCode`,
    label: G.getWindowLocale('titles:status-reason-code', 'Status Reason Code'),
    inputWrapperStyles: {
      width: 220,
      m: '20px 0 0 20px',
      display: G.ifElse(
        hasReasonCodes({ index, values, statusMessagesConfigs }),
        'flex',
        'none',
      ),
    },
  },
];

export const defaultStatusMessageFields = () => ({
  id: G.genShortId(),
  [GC.FIELD_STATUS_MESSAGE_DATE]: null,
  [GC.FIELD_STATUS_MESSAGE_CODE]: null,
  [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: null,
});

const fieldNullableRequired = Yup.string()
  .nullable(true)
  .required(G.getRequiredLocaleTxt());

export const validationSchema = Yup.object().shape({
  statusMessages: Yup.array().of(Yup.object().shape({
    [GC.FIELD_STATUS_MESSAGE_DATE]: fieldNullableRequired,
    [GC.FIELD_STATUS_MESSAGE_CODE]: fieldNullableRequired,
    [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: fieldNullableRequired,
  })),
});
