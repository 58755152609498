import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { MAIL_SENDING_REPLY_TO_OPTIONS } from '../../../helpers/options';
//////////////////////////////////////////////////

export const getValidationSchema = () => ({
  [GC.FIELD_TEMPLATE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getWindowLocale('validation:field-required', 'Field is Required')),
});

export const defaultValues = {
  [GC.FIELD_EMAILS]: null,
  [GC.FIELD_SEND_EMAILS]: false,
  [GC.FIELD_TEMPLATE_GUID]: null,
  [GC.FIELD_DOCUMENT_TYPE_GUIDS]: null,
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: null,
  [GC.FIELD_VENDOR_DOCUMENT_TYPE_GUIDS]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

export const getFields = (useVendorDocuments: boolean) => {
  const fields = [
    {
      isRequired: true,
      inputWrapperStyles,
      type: 'reactSelect',
      options: 'templateOptions',
      fieldName: GC.FIELD_TEMPLATE_GUID,
      label: ['titles:template', 'Template'],
    },
    {
      isMulti: true,
      inputWrapperStyles,
      type: 'reactSelect',
      fieldName: GC.FIELD_DOCUMENT_TYPE_GUIDS,
      options: 'documentTypeOptions',
      label: ['titles:document-type', 'Document Type'],
    },
    {
      isMulti: true,
      inputWrapperStyles,
      type: 'reactSelect',
      options: 'vendorDocumentTypeOptions',
      fieldName: GC.FIELD_VENDOR_DOCUMENT_TYPE_GUIDS,
      label: ['titles:vendor-document-type', 'Vendor Document Type'],
    },
    {
      type: 'toggle',
      fieldName: GC.FIELD_SEND_EMAILS,
      inputWrapperStyles: { mr: 25, mb: 25 },
      label: ['titles:send-emails', 'Send Emails'],
    },
    {
      type: 'reactSelect',
      label: ['titles:reply-to'],
      options: MAIL_SENDING_REPLY_TO_OPTIONS,
      fieldName: GC.FIELD_MAIL_SENDING_REPLY_TO,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(true, ['values', GC.FIELD_SEND_EMAILS], props),
          'block',
          'none',
        ),
      },
    },
    {
      type: 'multiEmail',
      label: ['titles:mail-to'],
      fieldName: GC.FIELD_EMAILS,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: (props: Object) => G.ifElse(
          R.pathEq(true, ['values', GC.FIELD_SEND_EMAILS], props),
          'block',
          'none',
        ),
      },
    },
  ];

  if (G.isFalse(useVendorDocuments)) return R.dropLast(1, fields);

  return fields;
};
