import React from 'react';
import * as R from 'ramda';
import { compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { LeafletMap } from '../../../components/leaflet-map';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// feature new-do
import Tabs from '../../new-do/components/tabs';
import RatePreview from '../../new-do/components/rate-preview';
import AdditionalSummary from '../../new-do/components/additional-summary';
import { RightSectionEmptyInfo } from '../../new-do/components/right-section';
import { LiteStopsSummaryWithDnD } from '../../new-do/components/stops-summary-with-dnd';
import {
  getRightSectionWidth,
  renderLeftRightContent,
  renderLeftRightEmptyInfo,
} from '../../new-do/helpers';
import {
  TAB_NAME_MAP,
  TAB_NAME_CLO_SUMMARY,
  TAB_NAME_RATE_CONFIRMATION,
} from '../../new-do/constants';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const tabSettings = [
  {
    name: TAB_NAME_CLO_SUMMARY,
    text: G.getWindowLocale('titles:clo-summary', 'CLO Summary'),
  },
  {
    name: TAB_NAME_RATE_CONFIRMATION,
    text: G.getWindowLocale('titles:rate-confirmation', 'Rate Confirmation'),
  },
  {
    name: TAB_NAME_MAP,
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];

const getLocationsWithMarkers = (locations: Array) => R.map((location: Object) => {
  const { title } = location;

  const pickup = R.equals(R.path(['stop', 'eventType'], location), GC.EVENT_TYPE_PICKUP);
  const eventEarlyDate = R.path(['stop', 'formData', GC.FIELD_LOAD_EVENT_EARLY_DATE], location);

  return R.mergeRight(location, {
    markerContent: <StopMarker {...location} />,
    color: G.getTheme('map.completedMarkerColor'),
    markerTitle: `${R.head(title)}${G.getNumberFromString(title)}`,
    infoContent: <StopInfo {...location} pickup={pickup} eventEarlyDate={eventEarlyDate} />,
  });
}, locations);

const mapEnhance = lifecycle({
  shouldComponentUpdate(nextProps: Object) {
    const { mapLocations } = this.props;

    return G.ifElse(R.equals(mapLocations, nextProps.mapLocations), false, true);
  },
});

export const MapLocations = mapEnhance((props: Object) => {
  const { mapLocations } = props;

  const center = R.path(['latLng'], R.head(mapLocations));

  const directionsColor = G.getTheme('map.directionsColor');

  const wrapperProps = {
    width: '100%',
    height: '100%',
    boxShadow: G.getTheme('shadows.standard'),
  };

  const routes = [
    { waypoints: [] },
    { color: directionsColor, waypoints: mapLocations.map((location: Object) => location.latLng) },
  ];

  const locationsWithMarkers = getLocationsWithMarkers(mapLocations);

  return (
    <Box {...wrapperProps}>
      { G.isNotNilAndNotEmpty(center) && (
        <LeafletMap
          routes={routes}
          center={center}
          height='calc(100vh - 245px)'
          withDynamicWaypoints={true}
          locations={locationsWithMarkers}
        />
      )}
    </Box>
  );
});

const integrationCarrierStyles = {
  fontSize: 14,
  margin: '0 15px',
  textMaxWidth: 500,
};

const renderTabContent = (props: Object) => {
  const { rightActiveTad } = props;

  if (R.equals(rightActiveTad, TAB_NAME_CLO_SUMMARY)) {
    return (
      <Box>
        <LiteStopsSummaryWithDnD {...props} />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
          text={R.path(['integrationCarriers', GC.FIELD_TRANSPORTING_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          text={R.path(['integrationCarriers', GC.FIELD_ORIGIN_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          text={R.path(['integrationCarriers', GC.FIELD_DESTINATION_CARRIER_NAME], props)}
          label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
        />
        <AdditionalSummary {...props} />
      </Box>
    );
  }

  if (R.equals(rightActiveTad, TAB_NAME_RATE_CONFIRMATION)) {
    return <RatePreview {...props} />;
  }

  if (R.equals(rightActiveTad, TAB_NAME_MAP)) {
    return <MapLocations {...props} />;
  }

  return null;
};

const RightSection = (props: Object) => {
  const { leftActiveTad, rightActiveTad, onClickTabCallback } = props;

  return (
    <Box
      mb={20}
      flexShrink={0}
      minWidth={662}
      maxWidth={1200}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
      width={getRightSectionWidth(leftActiveTad, rightActiveTad)}
    >
      {
        renderLeftRightContent(props) &&
        <Tabs
          overflow='auto'
          options={tabSettings}
          activeFromParent={rightActiveTad}
          onClickTabCallback={onClickTabCallback}
          defaultActiveName={TAB_NAME_CLO_SUMMARY}
        />
      }
      {
        renderLeftRightEmptyInfo(props) && <RightSectionEmptyInfo />
      }
      {renderLeftRightContent(props) && renderTabContent(props)}
    </Box>
  );
};

const enhance = compose(
  withHandlers({
    onClickTabCallback: ({ setActiveRightTab }: Object) => (activeName: Object) => {
      setActiveRightTab(activeName);
    },
  }),
);

export default enhance(RightSection);
