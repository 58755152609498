import React from 'react';
import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { TextComponent } from '../../text';
// component table
import {
  EnumElement,
  CurrencyField,
  BooleanElement,
  UomRelatedField,
} from '../../table/components/elements';
import { TableCell, FlexContainer } from '../../table/ui';
import { CustomElement } from '../../table/components/table-components';
import { getWidth, isColumnResizable } from '../../table/components/helpers';
//////////////////////////////////////////////////

const TableRowCellComponent = (props: Object) => (
  <TableCell
    key={props.key}
    width={props.width}
    minWidth={props.width}
    p={R.pathOr('8px', ['field', 'p'], props)}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      <TextComponent
        display='block'
        overflow='hidden'
        title={props.value}
        withEllipsis={true}
        maxWidth={`calc(${props.width}px - 8px)`}
      >
        {props.value}
      </TextComponent>
    </FlexContainer>
  </TableCell>
);

const UomRelatedFields = [
  GC.FIELD_WEIGHT,
  GC.FIELD_DISTANCE,
  GC.FIELD_ITEM_VOLUME,
  GC.FIELD_TEMPERATURE,
];

const TableRowCell = (props: Object) => {
  const {
    data,
    field,
    width,
    uiType,
    settings,
    resizable,
    fieldData,
    resizeByGuid,
    callbackData,
  } = props;

  let storedWidth;

  if (resizable) {
    const fieldName = R.prop(GC.FIELD_NAME, field);

    storedWidth = R.prop(fieldName, resizeByGuid);
  }

  const newWidth = R.or(storedWidth, width);

  if (G.isNotNilAndNotEmpty(settings.customComponent)) {
    return (
      <CustomElement
        data={data}
        field={field}
        width={newWidth}
        fieldData={fieldData}
        callbackData={callbackData}
        component={settings.customComponent}
      />
    );
  }

  if (R.and(R.includes(uiType, UomRelatedFields), G.isNotNilAndNotEmpty(fieldData))) {
    return (
      <UomRelatedField
        type={uiType}
        field={field}
        width={newWidth}
        fieldData={fieldData}
        withoutConversion={R.pathOr(false, ['withoutConversion'], settings)}
      />
    );
  }

  if (R.equals(uiType, GC.FIELD_CURRENCY)) {
    return (
      <CurrencyField data={data} value={fieldData} field={field} width={newWidth} />
    );
  }

  if (R.equals(uiType, 'boolean')) {
    return (
      <BooleanElement value={fieldData} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'booleanReverse')) {
    return (
      <BooleanElement value={R.not(fieldData)} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'enum')) {
    return (
      <EnumElement value={R.or(fieldData, '')} data={data} width={newWidth} field={field} />
    );
  }

  let valueToDisplay = fieldData;

  if (R.and(R.equals(uiType, 'enumLocale'), G.isNotNilAndNotEmpty(fieldData))) {
    valueToDisplay = G.getEnumLocale(fieldData);
  } else if (R.and(R.equals(uiType, 'uomLocale'), G.isNotNilAndNotEmpty(fieldData))) {
    valueToDisplay = G.getUomLocale(fieldData);
  } else if (R.equals(uiType, 'collectionOfEnums')) {
    valueToDisplay = R.compose(
      R.join(', '),
      R.map(R.compose(
        R.join(' '),
        R.map(G.toTitleCase),
        R.split('_'),
      )),
      R.uniq,
    )(R.or(fieldData, []));
  } else if (G.isArray(fieldData)) {
    valueToDisplay = G.createStringFromArray(fieldData, ', ');
  } else if (R.equals(uiType, 'date')) {
    const format = G.ifElse(R.gt(R.length(fieldData), 12), GC.FIELD_DATE_TIME, GC.FIELD_DATE);

    valueToDisplay = G.checkAndConvertMomentInstanceOrStringToFormattedDate(
      fieldData,
      G.getDateTimeFormatFromConfig(format),
    );
  } else if (R.equals(uiType, 'number')) {
    valueToDisplay = G.toFixed(fieldData);
  } else if (R.equals(uiType, 'location')) {
    const location = G.getPropFromObject(GC.SYSTEM_OBJECT_LOCATION, data);

    valueToDisplay = G.renderSmallAddressString(location);
  }

  return <TableRowCellComponent field={field} width={newWidth} value={valueToDisplay} />;
};

const getFieldData = (fieldName: string, data: Object, getDataByPath: any) => {
  if (R.isNil(fieldName)) return null;

  if (R.and(
    G.isTrue(getDataByPath),
    R.gt(R.length(R.split('.', fieldName)), 1)
  )) return P.$get(fieldName, data);

  return G.getPropFromObject(fieldName, data);
};

const renderTableRowCells = (props: Object) => {
  const {
    data,
    fields,
    rowIndex,
    filterProps,
    callbackData,
    resizeByGuid,
    tableSettings,
    columnSettings,
    withResizableColumns,
  } = props;

  let left = tableSettings.checkBoxCellWidth;

  return fields.map((field: Object, i: number) => {
    const fieldName = R.prop(GC.FIELD_NAME, field);
    const fieldProps = R.pathOr({}, [fieldName], filterProps);
    const settings = R.path([fieldName], columnSettings);
    const uiType = R.path(['type'], settings);
    const getDataByPath = R.path(['getDataByPath'], settings);
    const fieldData = getFieldData(fieldName, data, getDataByPath);
    const width = getWidth({ fieldName, filterProps, columnSettings, useNewTitleFilterInputs: true });

    const resizable = isColumnResizable({
      fieldName,
      fieldProps,
      columnSettings,
      withResizableColumns,
    });

    let storedWidth;

    if (resizable) {
      storedWidth = R.prop(fieldName, resizeByGuid);
    }

    const newWidth = R.or(storedWidth, width);

    left = R.add(left, R.or(newWidth, 0));

    return (
      <TableRowCell
        data={data}
        field={field}
        width={width}
        uiType={uiType}
        rowIndex={rowIndex}
        settings={settings}
        fieldData={fieldData}
        resizable={resizable}
        key={`field-table-${i}`}
        callbackData={callbackData}
        resizeByGuid={resizeByGuid}
      />
    );
  });
};

export {
  renderTableRowCells,
};

