import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withProps,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import TextComponent from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import { withSelectedFleetEntity } from '../../../components/select-fleet-entity';
// forms
import { Fieldset2 } from '../../../forms';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { workOrderStatusOptions } from '../../../helpers/options';
// hocs
import withAsyncSequence from '../../../hocs/with-async-sequence';
import {
  withAsyncGetUserGeneralListFullName,
  withAsyncGetServiceVendorListAvailable,
} from '../../../hocs/with-async-get-endpoint-hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
import { scrollableContainerCss4px } from '../../../ui/common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature work-order
import { Activities } from './activities';
import { changeWorkOrderStatusRequest, getItemListRequest as getWorkOrderListRequest } from '../actions';
import {
  workOrderDefaultValues,
  getWorkOrderFieldSettings,
  workOrderValidationSchema,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const serviceVendorTypes = R.join(
  ',',
  [GC.SERVICE_VENDOR_TYPE_GENERAL, GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE, GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE],
);

const enhance = compose(
  withSelectedFleetEntity,
  withAsyncGetUserGeneralListFullName,
  withAsyncGetServiceVendorListAvailable(serviceVendorTypes),
  withState('saveAndClose', 'setSaveAndClose', false),
  withState('serviceIssueList', 'setServiceIssueList', []),
  withPropsOnChange(['asyncUserGeneralListFullName'], ({ asyncUserGeneralListFullName }: Object) => {
    let assigneeOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUserGeneralListFullName)) {
      assigneeOptions = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return {
          [GC.FIELD_LABEL]: fullText,
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
        };
      }, asyncUserGeneralListFullName);
    }

    return { assigneeOptions };
  }),
  withPropsOnChange(['serviceIssueList'], ({ serviceIssueList }: Object) => {
    let serviceIssueOptions = [];

    if (G.isNotNilAndNotEmpty(serviceIssueList)) {
      serviceIssueOptions = R.map(({ guid, issueId, status, priority, workOrderInvoiceGuid }: Object) => ({
        status,
        priority,
        workOrderInvoiceGuid,
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: issueId,
      }), serviceIssueList);
    }

    return { serviceIssueOptions };
  }),
  withHandlers({
    handleGetServiceIssues: (props: Object) => async (fleetEntityGuid: string, workOrderGuid: string) => {
      const { isTruck, openLoader, closeLoader, setServiceIssueList } = props;

      if (G.isNilOrEmpty(fleetEntityGuid)) return setServiceIssueList([]);

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'truckServiceIssueList', 'trailerServiceIssueList'),
        endpointsMap,
      );

      const options = {
        params: { workOrderGuid, [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: fleetEntityGuid },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setServiceIssueList(data);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetServiceIssues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  withFormik({
    enableReinitialize: true,
    validationSchema: workOrderValidationSchema,
    mapPropsToValues: ({ initialValues, asyncSequence }: Object) => {
      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_NAME, asyncSequence, workOrderDefaultValues),
        workOrderDefaultValues,
      );

      return G.setInitialFormikValues(defaultFields, initialValues);
    },
    handleSubmit: (values: Object, { props, setValues }: Object) => {
      const {
        guid,
        entityType,
        saveAndClose,
        submitAction,
        asyncSequence,
        fleetEntityGuid,
        openedFromIssue,
        setInitialValues,
        handleGetServiceIssues,
      } = props;

      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (R.pathEq(asyncSequence, [GC.FIELD_NAME], values)) {
        valuesToUse = R.assoc(GC.FIELD_NAME, null, valuesToUse);
      }

      const successCallback = ({ data }: Object) => {
        setValues(R.mergeRight(valuesToUse, data));
        setInitialValues(R.assoc(GC.FIELD_ISSUE_GUIDS, G.getPropFromObject(GC.FIELD_ISSUE_GUIDS, valuesToUse), data));

        handleGetServiceIssues(fleetEntityGuid, guid);
      };

      submitAction({
        entityType,
        saveAndClose,
        openedFromIssue,
        values: valuesToUse,
        successCallback: G.ifElse(G.isFalse(saveAndClose), successCallback, null),
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getAsyncUserGeneralListFullName } = this.props;

      getAsyncUserGeneralListFullName();
    },
  }),
  pure,
);

const enhanceCreate = compose(
  withProps(() => ({
    sequenceConfigName: GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE,
    autogenerateConfigName: GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED,
    configsNamesArray: [GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE, GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED],
  })),
  withAsyncSequence,
  enhance,
  lifecycle({
    componentDidMount() {
      const { fleetEntityGuid, openedFromIssue, openedFromFleetProfile, handleGetServiceIssues } = this.props;

      if (R.or(openedFromIssue, openedFromFleetProfile)) {
        handleGetServiceIssues(fleetEntityGuid);
      }
    },
  }),
);

const enhanceEdit = compose(
  connect(null, { getWorkOrderListRequest, changeWorkOrderStatusRequest }),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  enhance,
  withHandlers({
    handleChangeStatus: (props: Object) => (entity: Object) => {
      const { openModal, setValues, changeWorkOrderStatusRequest } = props;

      const { guid, truckGuid } = entity;

      const entityType = G.ifElse(G.isNotNilAndNotEmpty(truckGuid), GC.FIELD_TRUCK, GC.FIELD_TRAILER);

      const component = (
        <SelectDropdownForm
          fieldWidth={270}
          optionRequired={true}
          options={workOrderStatusOptions}
          fieldLabel={G.getWindowLocale('titles:status', 'Status')}
          initialValues={{ option: G.getPropFromObject(GC.FIELD_STATUS, entity) }}
          submitAction={(status: Object) => changeWorkOrderStatusRequest({
            entityType,
            values: { guid, status },
            successCallback: (data: Object) => setValues(R.mergeRight(
              entity,
              R.pick([GC.FIELD_VERSION, GC.FIELD_STATUS, GC.FIELD_ACTUAL_COMPLETED_DATE], data),
            )),
          })}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getEditTitle(['titles:status', 'status']));

      openModal(modal);
    },
    handleGetInitialValues: (props: Object) => async () => {
      const { guid, isTruck, issueGuids, openLoader, closeLoader, setInitialValues } = props;

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'getCurrentTruckWordOrderEndpoint', 'getCurrentTrailerWordOrderEndpoint'),
        endpointsMap,
      )(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues({ ...data, issueGuids });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { guid, fleetEntityGuid, handleGetInitialValues, handleGetServiceIssues } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) {
        handleGetInitialValues();
        handleGetServiceIssues(fleetEntityGuid, guid);
      }
    },
  }),
);

const StatusSection = ({ values, handleChangeStatus }: Object) => {
  const { status, actualCompletedDate } = values;

  const statusInfo = `${G.getEnumLocale(R.or(status, ''))} ${
    R.equals(status, GC.STATUS_COMPLETED)
      ? `(${G.convertDateTimeToConfigFormat(actualCompletedDate)})`
      : ''
  }`;

  return (
    (
      <Flex mt={15} pr={15} justifyContent='flex-end'>
        <Box color={G.getTheme('colors.darkGrey')}>
          {G.getWindowLocale('titles:status', 'Status')}:
        </Box>
        <TextComponent
          mx='5px'
          maxWidth={270}
          fontWeight={700}
          title={statusInfo}
          withEllipsis={true}
        >
          {statusInfo}
        </TextComponent>
        <Flex
          cursor='pointer'
          color={G.getTheme('common.linkColor')}
          onClick={() => handleChangeStatus(values)}
          title={G.getWindowLocale('titles:change-status', 'Change Status')}
        >
          {I.renderEditIcon(G.getTheme('icons.iconColor'))}
        </Flex>
      </Flex>
    )
  );
};

const formId = 'work-order-form';

const WorkOrderForm = (props: Object) => {
  const {
    values,
    isEdit,
    handleSubmit,
    assigneeOptions,
    handleChangeStatus,
    serviceIssueOptions,
    serviceVendorListAvailableOptions,
  } = props;

  return (
    <Box width={380}>
      {
        isEdit && <StatusSection values={values} handleChangeStatus={handleChangeStatus} />
      }
      <form id={formId} onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          assigneeOptions={assigneeOptions}
          serviceIssueOptions={serviceIssueOptions}
          fields={getWorkOrderFieldSettings({ ...props, isEdit })}
          serviceVendorListAvailableOptions={serviceVendorListAvailableOptions}
          fieldsWrapperStyles={{ p: '25px 15px 0px', justifyContent: 'space-between' }}
        />
      </form>
    </Box>
  );
};

const WorkOrder = (props: Object) => {
  const { guid, values, initialValues, setSaveAndClose } = props;

  const isEdit = G.isNotNilAndNotEmpty(guid);

  const actionButtons = isEdit ? [{
    type: 'submit',
    action: () => setSaveAndClose(false),
    buttonStyles: { mr: 15, ml: 'auto' },
    displayText: G.getWindowLocale('actions:update', 'Update'),
  }] : [];

  return (
    <Box overflow='auto' maxHeight='87vh' css={scrollableContainerCss4px}>
      <Flex alignItems='stretch'>
        <WorkOrderForm {...props} isEdit={isEdit} />
        {
          isEdit &&
          <Activities
            {...props}
            primaryObjectGuid={guid}
            workOrderIssueGuids={R.pathOr([], [GC.FIELD_ISSUE_GUIDS], initialValues)}
            maxHeight={G.ifElse(G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_ISSUE_GUIDS, values)), 422, 538)}
          />
        }
      </Flex>
      <FormFooter2
        formId={formId}
        actionButtons={actionButtons}
        submitAction={() => setSaveAndClose(true)}
        submitBtnStyles={G.ifElse(isEdit, { width: 140 }, null)}
        submitBtnText={G.getWindowLocale(
          ...G.ifElse(isEdit, ['actions:save-and-close', 'Save And Close'], ['actions:submit', 'Submit']),
        )}
        boxStyles={{
          p: 15,
          borderTop: '1px solid',
          bg: G.getTheme('colors.whiteGrey'),
          borderColor: G.getTheme('colors.lightGrey'),
        }}
      />
    </Box>
  );
};

const EditWorkOrderForm = enhanceEdit(WorkOrder);
const CreateWorkOrderForm = enhanceCreate(WorkOrder);

export {
  EditWorkOrderForm,
  CreateWorkOrderForm,
};
