// general
export const GENERAL_CONFIG_GROUP = 'general';

export const GENERAL_SERVICES = 'general_services';
export const GENERAL_EQUIPMENTS = 'general_equipments';
export const GENERAL_MODE_TRANSPORTATION = 'general_mode_transportation';
export const GENERAL_NOTIFY_FLEET_EMAIL = 'general_fleetNotificationEmail';
export const GENERAL_NOTIFY_ROUTE_EMAIL = 'general_routeNotificationEmail';
export const GENERAL_TRANSPORTATION_SERVICE_TYPE = 'general_transportationServiceType';
export const GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM = 'general_uomCalculator_defaultUomSystem';
// custom
export const CUSTOM_ACCESSORIALS_CONFIG = 'CUSTOM_ACCESSORIALS_CONFIG';
export const CUSTOM_DISTANCE_CALCULATOR_CONFIG = 'CUSTOM_DISTANCE_CALCULATOR_CONFIG';
export const CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG = 'CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG';
export const CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG = 'CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG';
export const CUSTOM_EXTERNAL_TRANSPORTATION_MODE_MAPPING_CONFIG = 'CUSTOM_EXTERNAL_TRANSPORTATION_MODE_MAPPING_CONFIG';
// routeName
export const GENERAL_ROUTE_NAME_PREFIX = 'general_routeName_prefix';
export const GENERAL_ROUTE_NAME_SEQUENCE = 'general_routeName_sequence';
export const GENERAL_ROUTE_NAME_AUTOGENERATED = 'general_routeName_autogenerated';
// statusCheck
export const GENERAL_STATUS_CHECK_FREQUENCY = 'general_statusCheck_frequency';
// enterprise
export const GENERAL_BRANCH_SUBTYPE = 'general_enterprise_subtype';
export const GENERAL_BRANCH_TIMEZONE = 'general_enterprise_timeZone';
export const GENERAL_BRANCH_DATE_TIME_FORMAT = 'general_enterprise_dateTimeFormat';
export const GENERAL_BRANCH_DEFAULT_CURRENCY = 'general_enterprise_defaultCurrency';
export const GENERAL_BRANCH_ACCOUNT_NUMBER_SEQUENCE = 'general_enterprise_accountNumber_sequence';
export const GENERAL_BRANCH_USE_COMMISSION_ASSIGNMENT = 'general_enterprise_use_commission_assignment';
export const GENERAL_BRANCH_ACCOUNT_NUMBER_AUTOGENERATED = 'general_enterprise_accountNumber_autogenerated';
// routeDraftName
export const GENERAL_DRAFT_ROUTE_NAME_PREFIX = 'general_routeDraftName_prefix';
export const GENERAL_DRAFT_ROUTE_NAME_SEQUENCE = 'general_routeDraftName_sequence';
export const GENERAL_DRAFT_ROUTE_NAME_AUTOGENERATED = 'general_routeDraftName_autogenerated';
// distanceCalculator
export const GENERAL_DC_CONFIG = 'general_distanceCalculator_config';
export const GENERAL_DC_CALC_UNIT = 'general_distanceCalculator_unit';
export const GENERAL_DC_BORDER_OPEN = 'general_distanceCalculator_borderOpen';
export const GENERAL_DC_TRUCK_WIDTH = 'general_distanceCalculator_truckWidth';
export const GENERAL_DC_TRUCK_LENGTH = 'general_distanceCalculator_truckLength';
export const GENERAL_DC_TRUCK_HEIGHT = 'general_distanceCalculator_truckHeight';
export const GENERAL_DC_PC_MILER_URL = 'general_distanceCalculator_pcMiler_url';
export const GENERAL_DC_ROUTING_TYPE = 'general_distanceCalculator_routingType';
export const GENERAL_DC_TOM_TOM_API_KEY = 'general_distanceCalculator_tomTom_apiKey';
export const GENERAL_DC_TOLL_DISCOURAGE = 'general_distanceCalculator_tollDiscourage';
export const GENERAL_DC_PC_MILER_API_KEY = 'general_distanceCalculator_pcMiler_apiKey';
export const GENERAL_DC_GOOGLE_MAPS_AVOID = 'general_distanceCalculator_googleMaps_avoid';
export const GENERAL_DC_PRO_MILES_USERNAME = 'general_distanceCalculator_proMiles_username';
export const GENERAL_DC_PRO_MILES_PASSWORD = 'general_distanceCalculator_proMiles_password';
export const GENERAL_DC_GOOGLE_MAPS_API_KEY = 'general_distanceCalculator_googleMaps_apiKey';
export const GENERAL_DC_PRO_MILES_VEHICLE_TYPE = 'general_distanceCalculator_proMiles_vehicleType';
export const GENERAL_DC_PRO_MILES_COMPANY_CODE = 'general_distanceCalculator_proMiles_companyCode';
export const GENERAL_DC_USE_GEO_DISTANCE_FOR_COST_ALLOCATION =
  'general_distanceCalculator_useGeoDistanceForCostAllocation';

// master
export const OPTIONAL_CONFIG_GROUP = 'optional';

export const OPTIONAL_GBHUNT_CALCULATOR_ENABLED = 'optional_gbhuntCalculator_enabled';
export const OPTIONAL_QB_OVERRIDE_URL_REFERENCES = 'optional_qb_overrideUrl_references';
export const OPTIONAL_GBHUNT_CALCULATOR_USE_ON_TEL = 'optional_gbhuntCalculator_useOnTel';
export const OPTIONAL_QB_OVERRIDE_URL_CONFIRMATION_REFERENCE = 'optional_qb_overrideUrl_confirmationReference';

// clo
export const CLO_CONFIG_GROUP = 'clo';

export const CLO_ORDER_TYPE_DROPDOWN = 'clo_orderType';
export const CLO_GENERAL_SHIP_TO = 'clo_general_shipTo';
export const CLO_GENERAL_SHIP_FROM = 'clo_general_shipFrom';
export const CLO_GENERAL_BILL_TO = 'clo_general_billTo_location';
export const CLO_GENERAL_DEFAULT_ITEM = 'clo_general_defaultItem';
export const CLO_AUTO_REFERENCE_1_TYPE = 'clo_autoReference1_type';
export const CLO_AUTO_REFERENCE_2_TYPE = 'clo_autoReference2_type';
export const CLO_AUTO_REFERENCE_3_TYPE = 'clo_autoReference3_type';
export const CLO_GENERAL_REMIT_TO = 'clo_general_remitTo_location';
export const CLO_GENERAL_TIME_INTERVAL = 'clo_general_timeInterval';
export const CLO_GENERAL_DROP_INTERVAL = 'clo_general_dropInterval';
export const CLO_GENERAL_USE_ORDER_TYPE = 'clo_general_useOrderType';
export const CLO_AUTO_REFERENCE_3_VALUE = 'clo_autoReference3_value';
export const CLO_AUTO_REFERENCE_1_VALUE = 'clo_autoReference1_value';
export const CLO_AUTO_REFERENCE_2_VALUE = 'clo_autoReference2_value';
export const CLO_UI_MULTI_CREATE_FIELDS = 'clo_ui_multicreateFields';
export const CLO_GENERAL_USE_CONTAINERS = 'clo_general_useContainers';
export const CLO_PRIMARY_REFERENCE_TYPE = 'clo_primaryReference_type';
export const CLO_GENERAL_DROP_EARLY_TIME = 'clo_general_dropEarlyTime';
export const CLO_GENERAL_PICKUP_INTERVAL = 'clo_general_pickupInterval';
export const CLO_GENERAL_COPY_REFERENCES = 'clo_general_copyReferences';
export const CLO_GENERAL_EVENTS_INTERVAL = 'clo_general_eventsInterval';
export const CLO_PRIMARY_REFERENCE_PREFIX = 'clo_primaryReference_prefix';
export const CLO_UI_ADDITIONAL_ITEM_FIELDS = 'clo_ui_additionalItemFields';
export const CLO_AUTO_REFERENCE_2_SEQUENCE = 'clo_autoReference2_sequence';
export const CLO_AUTO_REFERENCE_1_SEQUENCE = 'clo_autoReference1_sequence';
export const CLO_GENERAL_PAYMENT_TERMS = 'clo_general_billTo_paymentTerms';
export const CLO_AUTO_REFERENCE_3_SEQUENCE = 'clo_autoReference3_sequence';
export const CLO_GENERAL_PICKUP_EARLY_TIME = 'clo_general_pickupEarlyTime';
export const CLO_GENERAL_TEL_CREATION_MODE = 'clo_general_telCreationMode';
export const CLO_GENERAL_USE_LINKED_ORDERS = 'clo_general_useLinkedOrders';
export const CLO_GENERAL_APPROVAL_REQUIRED = 'clo_general_approvalRequired';
export const CLO_GENERAL_DEFAULT_EQUIPMENT = 'clo_general_defaultEquipment';
export const CLO_GENERAL_DEFAULT_ORDER_TYPE = 'clo_general_defaultOrderType';
export const CLO_UI_SINGLE_APPOINTMENT_TIME = 'clo_ui_singleAppointmentTime';
export const CLO_GENERAL_FILTER_BY_DIVISION = 'clo_general_filterByDivision';
export const CLO_GENERAL_DEFAULT_REFERENCES = 'clo_general_defaultReferences';
export const CLO_PRIMARY_REFERENCE_SEQUENCE = 'clo_primaryReference_sequence';
export const CLO_GENERAL_LOAD_CANCEL_REASON = 'clo_general_load_cancelReason';
export const CLO_GENERAL_ORDER_TYPE_REQUIRED = 'clo_general_orderTypeRequired';
export const CLO_GENERAL_COPY_ALL_REFERENCES = 'clo_general_copyAllReferences';
export const CLO_GENERAL_SYNCHRONIZE_WITH_TEL = 'clo_general_synchronizeWithTel';
export const CLO_GENERAL_SHOW_TEL_DOCUMENTS_ON_CLO = 'clo_general_showDocuments';
export const CLO_GENERAL_MAKE_BOOKED_BY_ONE_TEL = 'clo_general_makeBookedByOneTel';
export const CLO_GENERAL_USE_ENTERPRISE_BILL_TO = 'clo_general_useEnterpriseBillTo';
export const CLO_UI_SEPARATE_EVENT_DATE_AND_TIME = 'clo_ui_separateEventDateAndTime';
export const CLO_GENERAL_CUSTOMER_REFERENCE_TYPE = 'clo_general_customerReferenceType';
export const CLO_PRIMARY_REFERENCE_AUTOGENERATED = 'clo_primaryReference_autogenerated';
export const CLO_PRIMARY_REFERENCE_DIVISION_PREFIX = 'clo_primaryReference_divisionPrefix';
export const CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM = 'clo_general_autoCreateContainerItem';
export const CLO_GENERAL_CONFIRMATION_DOCUMENT_TYPE = 'clo_general_confirmationDocumentType';
export const CLO_GENERAL_STATUS_MESSAGE_COPY_FROM_TEL = 'clo_general_statusMessage_copyFromTel';
export const CLO_CROSS_DIVISION_DISPATCH_BILL_TO_LOCATION = 'clo_crossDivisionDispatch_billTo_location';
export const CLO_GENERAL_STATUS_MESSAGE_EXCLUDE_COPY_FROM_TEL = 'clo_general_statusMessage_excludeCopyFromTel';
export const CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_ACCESSORIAL = 'clo_general_cancelClo_defaultChargeAssessorial';
export const CLO_CROSS_DIVISION_PRIMARY_REFERENCE_COPY_TYPE = 'clo_crossDivisionDispatch_primaryReferenceCopyType';
export const CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_CUSTOMER_RATE = 'clo_general_cancelClo_defaultChargeCustomerRate';
export const CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_DRIVER_CARRIER_RATE =
  'clo_general_cancelClo_defaultChargeDriverCarrierRate';
export const CLO_CROSS_DIVISION_COPY_PRIMARY_REF_FROM_TEL_AS_REFERENCE =
  'clo_crossDivisionDispatch_copyPrimaryRefFromTelAsReference';
// clo item
export const CLO_ITEM_MAX_WIDTH = 'clo_item_maxWidth';
export const CLO_ITEM_MAX_LENGTH = 'clo_item_maxLengths';
export const CLO_ITEM_MAX_HEIGHT = 'clo_item_maxHeights';
export const CLO_ITEM_DEFAULT_TYPE = 'clo_item_defaultType';
export const CLO_ITEM_DIMENSION_UOM = 'clo_item_dimensionUOM';
export const CLO_ITEM_DEFAULT_COUNTRY = 'clo_item_default_country';
export const CLO_ITEM_REQUIRED_FIELDS = 'clo_item_requiredItemFields';
export const CLO_ITEM_DEFAULT_WEIGHT_UOM = 'clo_item_defaultWeightUOM';
export const CLO_ITEM_DAMAGE_REPORT_TYPE = 'clo_item_damageReportType';
export const CLO_ITEM_DAMAGE_REPORT_AREA = 'clo_item_damageReportArea';
export const CLO_ITEM_USE_DIFFERENT_TYPES = 'clo_item_useDifferentTypes';
export const CLO_ITEM_DEFAULT_PACKAGE_TYPE = 'clo_item_defaultPackageType';
export const CLO_ITEM_DAMAGE_REPORT_SEVERITY = 'clo_item_damageReportSeverity';
export const CLO_ITEM_DEFAULT_TEMPERATURE_UOM = 'clo_item_defaultTemperatureUOM';
export const CLO_ITEM_FREIGHT_CLASS_CALCULATOR = 'clo_item_freightClassCalculator';
export const CLO_ITEM_REQUIRED_DAMAGE_REPORT_AREAS = 'clo_item_requiredDamageReportAreas';
// clo dispatch board
export const CLO_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES = 'clo_dispatch_board_used_global_search_types';
export const CLO_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE = 'clo_dispatch_board_default_global_search_type';
// clo default
export const CLO_DEFAULT_DROP_LOCATION_TYPE = 'clo_default_dropLocationType';
export const CLO_DEFAULT_SPECIAL_INSTRUCTION = 'clo_default_specialInstruction';
export const CLO_DEFAULT_PICKUP_LOCATION_TYPE = 'clo_default_pickupLocationType';
// clo rate
export const CLO_RATE_SYNC_CHARGES = 'clo_rate_sync_syncCharges';
export const CLO_RATE_SYNC_UPLIFT_UNIT = 'clo_rate_sync_upliftUnit';
export const CLO_RATE_SYNC_UPLIFT_VALUE = 'clo_rate_sync_upliftValue';
export const CLO_RATE_SYNC_UPLIFT_APPLY_TO = 'clo_rate_sync_upliftApplyTo';
export const CLO_GENERAL_RATE_SERVICE_TYPE = 'clo_general_rate_serviceType';
export const CLO_RATE_DEFAULT_FUEL_ASSESSORIAL = 'clo_rate_defaultFuelAssessorial';
export const CLO_GENERAL_RATE_TRANSPORTATION_MODE = 'clo_general_rate_transportationMode';
export const CLO_RATE_SYNC_DEFAULT_FUEL_ASSESSORIAL = 'clo_rate_sync_defaultFuelAssessorial';
export const CLO_RATE_SYNC_RATE_FROM_CUSTOMER_INVOICE = 'clo_rate_sync_rateFromCustomerInvoice';
export const CLO_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES = 'clo_rate_recalculateFuelCharge_OnStatuses';
// clo rate shop
export const CLO_RATE_SHOP_QUOTE_ID_SEQUENCE = 'clo_rateShop_quoteIdSequence';
// clo quote
export const CLO_QUOTE_DEFAULT_EMAIL = 'clo_quote_defaultEmail';
export const CLO_QUOTE_EXPIRATION_DAYS = 'clo_quote_expirationDays';
export const CLO_QUOTE_DEFAULT_SUBJECT = 'clo_quote_defaultSubject';
export const CLO_QUOTE_DEFAULT_MESSAGE = 'clo_quote_defaultMessage';
export const CLO_QUOTE_SHOW_TRIP_PRICING = 'clo_quote_showTripPricing';
export const CLO_QUOTE_CANCEL_REASON_CODE = 'clo_quote_cancelReasonCode';
export const CLO_QUOTE_HIDE_ADDRESS_FIELDS = 'clo_quote_hideAddressFields';
export const CLO_QUOTE_EMAIL_DIVISION_NAME = 'clo_quote_emailDivisionName';
export const CLO_QUOTE_DECLINE_REASON_CODE = 'clo_quote_declineReasonCode';
export const CLO_QUOTE_QUOTE_NUMBER_SEQUENCE = 'clo_quote_quoteNumber_sequence';
export const CLO_QUOTE_ITEM_REQUIRED_FIELDS = 'clo_quote_item_requiredItemFields';
export const CLO_QUOTE_REQUEST_NUMBER_SEQUENCE = 'clo_quoteRequestNumber_sequence';
export const CLO_QUOTE_ITEM_ADDITIONAL_ITEM_FIELDS = 'clo_quote_item_additionalItemFields';
export const CLO_QUOTE_USE_QUOTE_NUMBER_AS_PRIMARY_REFERENCE = 'clo_quote_useQuoteNumberAsPrimaryReference';

// tel
export const TEL_CONFIG_GROUP = 'tel';

export const TEL_AUTO_REFERENCE_1_TYPE = 'tel_autoReference1_type';
export const TEL_AUTO_REFERENCE_2_TYPE = 'tel_autoReference2_type';
export const TEL_AUTO_REFERENCE_3_TYPE = 'tel_autoReference3_type';
export const TEL_GENERAL_SYNC_TRAILERS = 'tel_general_syncTrailers';
export const TEL_AUTO_REFERENCE_1_VALUE = 'tel_autoReference1_value';
export const TEL_AUTO_REFERENCE_2_VALUE = 'tel_autoReference2_value';
export const TEL_AUTO_REFERENCE_3_VALUE = 'tel_autoReference3_value';
export const TEL_PRIMARY_REFERENCE_TYPE = 'tel_primaryReference_type';
export const TEL_GENERAL_DEFAULT_BRANCH = 'tel_general_defaultBranch';
export const TEL_GENERAL_COPY_REFERENCES = 'tel_general_copyReferences';
export const TEL_GENERAL_BILL_TO_REPLACE = 'tel_general_billTo_replace';
export const TEL_PRIMARY_REFERENCE_PREFIX = 'tel_primaryReference_prefix';
export const TEL_GENERAL_BILL_TO_LOCATION = 'tel_general_billTo_location';
export const TEL_AUTO_REFERENCE_1_SEQUENCE = 'tel_autoReference1_sequence';
export const TEL_AUTO_REFERENCE_2_SEQUENCE = 'tel_autoReference2_sequence';
export const TEL_CLO_RELATED_DOCUMENTS = 'tel_general_cloRelatedDocuments';
export const TEL_AUTO_REFERENCE_3_SEQUENCE = 'tel_autoReference3_sequence';
export const TEL_PRIMARY_REFERENCE_SEQUENCE = 'tel_primaryReference_sequence';
export const TEL_GENERAL_COPY_ALL_REFERENCES = 'tel_general_copyAllReferences';
export const TEL_GENERAL_SYNCHRONIZE_WITH_CLO = 'tel_general_synchronizeWithClo';
export const TEL_NOTIFICATION_DOWNTIME_HOURS = 'tel_notification_downtimeHours';
export const TEL_GENERAL_ADJUST_DELIVERY_DATE = 'tel_general_adjustDeliveryDate';
export const TEL_GENERAL_CREATE_ROUTE_WITH_TEL = 'tel_general_createRouteWithTel';
export const TEL_GENERAL_BILL_TO_PAYMENT_TERMS = 'tel_general_billTo_paymentTerms';
export const TEL_GENERAL_RATE_EXPIRATION_TIMEOUT = 'tel_general_rateExpirationTimeout';
export const TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK = 'tel_routeBuilder_defaultCrossdock';
export const TEL_PRIMARY_REFERENCE_AUTOGENERATED = 'tel_primaryReference_autogenerated';
export const TEL_PRIMARY_REFERENCE_COPY_FROM_CLO = 'tel_primaryReference_copy_from_clo';
export const TEL_NOTIFICATION_HOURS_BEFORE_EVENT_DATE = 'tel_notification_hoursBeforeEventDate';
export const TEL_GENERAL_SEND_TENDER_DOCUMENT_ON_ACCEPT = 'tel_general_sendTenderDocumentOnAccept';
export const TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL = 'tel_general_allowStopCompleteWithoutArrival';
export const TEL_FLEET_ASSIGNMENT_DISABLE_AVAILABILITY_CHECK = 'tel_fleetAssignment_disableAvailabilityCheck';
export const TEL_GENERAL_ALLOW_ADJUST_DELIVERY_DATE_TO_WEEKEND = 'tel_general_allowAdjustDeliveryDateToWeekend';
export const TEL_GENERAL_ALLOW_DELIVERY_WITHOUT_PICKUP_COMPLETE = 'tel_general_allowDeliveryWithoutPickupComplete';
export const TEL_GENERAL_TRACKING_NUMBER_REQUIRED_FOR_TRANSPORTATION_MODES =
  'tel_general_trackingNumberRequiredForTransportationModes';
// tel driver rate
export const TEL_DRIVER_RATE_DEFAULT_MODE = 'tel_driverRate_defaultMode';
export const TEL_DRIVER_RATE_TRAILER_REQUIRED = 'tel_driverRate_trailerRequired';
export const TEL_DRIVER_RATE_SHOW_RAILWAY_SECTION = 'tel_driverRate_showRailwaySection';
export const TEL_DRIVER_RATE_DEFAULT_SERVICE_TYPE = 'tel_driverRate_defaultServiceType';
export const TEL_DRIVER_RATE_START_POINT_REQUIRED = 'tel_driverRate_startPointRequired';
export const TEL_DRIVER_RATE_FINISH_POINT_REQUIRED = 'tel_driverRate_finishPointRequired';
export const TEL_DRIVER_RATE_SHOW_START_FINISH_POINT = 'tel_driverRate_showStartFinishPoint';
export const TEL_RATE_RECALCULATE_FUEL_CHARGE_ON_STATUSES = 'tel_rate_recalculateFuelCharge_OnStatuses';
export const TEL_RATE_UPDATE_RATE_WHEN_CUSTOMER_RATE_CHANGES = 'tel_rate_updateRate_whenCustomerRateChanges';
// tel carrier rate
export const TEL_CARRIER_RATE_DEFAULT_MODE = 'tel_carrierRate_defaultMode';
export const TEL_CARRIER_RATE_DEFAULT_SERVICE_TYPE = 'tel_carrierRate_defaultServiceType';
// tel dispatch board
export const TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES = 'tel_dispatch_board_used_global_search_types';
export const TEL_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE = 'tel_dispatch_board_default_global_search_type';

// templates
export const TEMPLATES_CONFIG_GROUP = 'templates';

export const TEMPLATES_LOCATION_TYPE = 'templates_locationType';
export const TEMPLATES_CONTACT_BOOK_AUTO_SAVED = 'templates_contactBook_autoSaved';
export const TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE = 'templates_locationTemplateId_sequence';
export const TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED = 'templates_locationTemplateId_autogenerated';
export const TEMPLATES_LOCATION_TEMPLATE_BRANCH_FILTER_TYPE = 'templates_locationTemplate_branchFilterType';
export const TEMPLATES_LOCATION_TEMPLATE_BLOCKED_LOCATION_TYPES = 'templates_locationTemplate_blockedLocationTypes';

// password
export const PASSWORD_CONFIG_GROUP = 'password';

export const PASSWORD_MIN_NUM = 'password_minNum';
export const PASSWORD_MIN_UPPER = 'password_minUpper';
export const PASSWORD_MIN_LOWER = 'password_minLower';
export const PASSWORD_MIN_LENGTH = 'password_minLength';
export const PASSWORD_MIN_SPECIAL = 'password_minSpecial';
export const PASSWORD_VALIDATION_ENABLE = 'password_validationEnable';

// fleet-general
export const FLEET_GENERAL_CONFIG_GROUP = 'fleetGeneral';

export const FLEET_GENERAL_SERVICE_ISSUE_TYPE = 'fleetGeneral_serviceIssueType';

export const FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE = 'fleetGeneral_serviceIssueId_sequence';
export const FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED = 'fleetGeneral_serviceIssueId_autogenerated';

export const FLEET_GENERAL_INSPECTION_ID_SEQUENCE = 'fleetGeneral_inspectionId_sequence';
export const FLEET_GENERAL_INSPECTION_ID_AUTOGENERATED = 'fleetGeneral_inspectionId_autogenerated';

export const FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE = 'fleetGeneral_workOrderName_sequence';
export const FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED = 'fleetGeneral_workOrderName_autogenerated';

export const FLEET_GENERAL_SERVICE_ISSUE_AUTO_ASSIGN_SERVICE_VENDOR =
  'fleetGeneral_serviceIssue_autoAssignServiceVendor';

export const FLEET_COMPONENT_TYPE = 'fleet_componentType';
export const FLEET_EQUIPMENT_SERVICE_TYPE = 'fleet_equipmentServiceType';
export const FLEET_COMPONENT_MANUFACTURER = 'fleet_componentManufacturer';

// driver
export const DRIVER_CONFIG_GROUP = 'driver';

export const DRIVER_CITIZEN = 'driver_citizen';
export const DRIVER_CONTACT_TYPE = 'driver_contactType';
export const DRIVER_DOCUMENT_TYPE = 'driver_documentType';
export const DRIVER_OUT_DUTY_REASON = 'driver_outOfDutyReason';
export const DRIVER_CARDS_WARNING = 'driver_card_warningPeriod';
export const DRIVER_ASSIGNED_DIVISION = 'driver_assignedDivision';
export const DRIVER_DOMICILE_TERMINAL = 'driver_domicileTerminal';
export const DRIVER_UNEMPLOYED_REASON = 'driver_unemploymentReason';
export const DRIVER_CERTIFICATION_NAME = 'driver_certificationName';
export const DRIVER_CERTIFICATION_TYPE = 'driver_certificationType';
export const DRIVER_CARDS_PLANING_PERIOD = 'driver_card_planingPeriod';
export const DRIVER_MINIMUM_BREAK_MINUTES = 'driver_minimumBreakMinutes';
export const DRIVER_SAFETY_VIOLATION_TYPE = 'driver_safetyViolation_type';
export const DRIVER_REQUIRED_DOCUMENT_TYPES = 'driver_requiredDocumentTypes';
export const DRIVER_PENDING_ACTIVITY_STATUS = 'driver_pendingActivityStatus';
export const DRIVER_SAFETY_VIOLATION_SEVERITY = 'driver_safetyViolation_severity';
export const DRIVER_REQUIRED_DOCUMENT_WARNING_LEVEL = 'driver_requiredDocumentWarningLevel';
export const DRIVER_SAFETY_VIOLATION_DISCIPLINARY_ACTION = 'driver_safetyViolation_disciplinaryAction';

// gps integration
export const DRIVER_GPS_STATUS_CODE = 'driver_gpsIntegration_statusCode';
export const DRIVER_GPS_HOURS_BEFORE_LAST_EVENT_LATE_DATE = 'driver_gpsIntegration_hoursAfterLastEventLateDate';
export const DRIVER_GPS_HOURS_BEFORE_FIRST_EVENT_EARLY_DATE = 'driver_gpsIntegration_hoursBeforeFirstEventEarlyDate';

// autoarrive
export const DRIVER_AUTO_ARRIVE_ENABLED = 'driver_autoarrive_enabled';
export const DRIVER_AUTO_ARRIVE_RADIUS_METERS = 'driver_autoarrive_radiusMeters';

// truck
export const TRUCK_CONFIG_GROUP = 'truck';

export const TRUCK_TRUCK_TYPE = 'truck_truckType';
export const TRUCK_DOCUMENT_TYPE = 'truck_documentType';
export const TRUCK_INSURANCE_TYPE = 'truck_insuranceType';
export const TRUCK_OUT_SERVICE_REASON = 'truck_outOfServiceReason';
export const TRUCK_UNAVAILABILITY_REASON = 'truck_unavailabilityReason';
export const TRUCK_COMPONENT_MANUFACTURER = 'truck_componentManufacturer';
export const TRUCK_REQUIRED_DOCUMENT_TYPES = 'truck_requiredDocumentTypes';
export const TRUCK_IFTA_EXCLUDE_FUEL_STOPS = 'truck_ifta_excludeFuelStops';
export const TRUCK_ADDITIONAL_EQUIPMENT_TYPE = 'truck_additionalEquipmentType';
export const TRUCK_DEACTIVATE_ON_SERVICE_ISSUE = 'truck_deactivateOnServiceIssue';
export const TRUCK_DEFAULT_OUT_OF_SERVICE_REASON = 'truck_defaultOutOfServiceReason';
export const TRUCK_REQUIRED_DOCUMENT_WARNING_LEVEL = 'truck_requiredDocumentWarningLevel';
// maintenance
export const TRUCK_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE =
  'truck_maintenance_aiUpload_defaultDocumentType';
export const TRUCK_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE =
  'truck_maintenance_documents_defaultDocumentType';
export const TRUCK_SERVICE_ID_SEQUENCE = 'truck_serviceId_sequence';
export const TRUCK_SERVICE_ID_AUTOGENERATED = 'truck_serviceId_autogenerated';

// trailer
export const TRAILER_CONFIG_GROUP = 'trailer';

export const TRAILER_DOOR_TYPE = 'trailer_doorType';
export const TRAILER_TRAILER_TYPE = 'trailer_trailerType';
export const TRAILER_DOCUMENT_TYPE = 'trailer_documentType';
export const TRAILER_BODY_MATERIAL = 'trailer_bodyMaterial';
export const TRAILER_INSURANCE_TYPE = 'trailer_insuranceType';
export const TRAILER_EQUIPMENT_TYPE = 'trailer_equipmentType';
export const TRAILER_OUT_SERVICE_REASON = 'trailer_outOfServiceReason';
export const TRAILER_SPECIFICATION_CLASS = 'trailer_specificationClass';
export const TRAILER_MANAGE_TRAILERS_FROM = 'trailer_manageTrailersFrom';
export const TRAILER_UNAVAILABILITY_REASON = 'trailer_unavailabilityReason';
export const TRAILER_SHOW_IN_BRANCH_GUIDS = 'trailer_showInEnterpriseGuids';
export const TRAILER_REQUIRED_DOCUMENT_TYPES = 'trailer_requiredDocumentTypes';
export const TRAILER_REFTIGERATION_MANUFACTURER = 'trailer_refrigerationManufacturer';
export const TRAILER_DEACTIVATE_ON_SERVICE_ISSUE = 'trailer_deactivateOnServiceIssue';
export const TRAILER_DEFAULT_OUT_OF_SERVICE_REASON = 'trailer_defaultOutOfServiceReason';
export const TRAILER_REQUIRED_DOCUMENT_WARNING_LEVEL = 'trailer_requiredDocumentWarningLevel';
// maintenance
export const TRAILER_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE =
  'trailer_maintenance_aiUpload_defaultDocumentType';
export const TRAILER_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE =
  'trailer_maintenance_documents_defaultDocumentType';
export const TRAILER_SERVICE_ID_SEQUENCE = 'trailer_serviceId_sequence';
export const TRAILER_SERVICE_ID_AUTOGENERATED = 'trailer_serviceId_autogenerated';

// communication
export const COMMUNICATION_CONFIG_GROUP = 'communication';

export const COMMUNICATION_DOCUMENT_TYPE = 'communication_documentType';
export const COMMUNICATION_REASON_CODE = 'communication_statusReasonCode';
export const COMMUNICATION_SMS_SENDER_TYPE = 'communication_sms_senderType';
export const COMMUNICATION_BRANCH_SIGNATURE = 'communication_mailing_signature';
export const COMMUNICATION_BRANCH_EMAIL = 'communication_mailing_enterpriseEmail';
export const COMMUNICATION_NOTIFY_ROUTE_EMAIL = 'communication_routeNotificationEmail';
export const COMMUNICATION_BRANCH_DOCUMENT_TYPE = 'communication_enterprise_documentType';
export const COMMUNICATION_MAILING_DEFAULT_REPLY_TO = 'communication_mailing_defaultReplyTo';
export const COMMUNICATION_SMS_BRANCH_SENDER_NAME = 'communication_sms_enterpriseSenderName';
export const COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL = 'communication_accountingDepartmentEmail';
export const COMMUNICATION_DISPATCH_DECLINE_REASON_CODE = 'communication_dispatchDeclineReasonCode';
export const COMMUNICATION_MAILING_LOCK_DEFAULT_REPLY_TO = 'communication_mailing_lockDefaultReplyTo';
export const COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL = 'communication_email_useBillToContactEmail';

// custom
export const CUSTOM_EDI_CONFIG = 'CUSTOM_EDI_CONFIG';
export const CUSTOM_DOCUMENT_CONFIG = 'CUSTOM_DOCUMENT_CONFIG';
export const CUSTOM_FUEL_CARD_CONFIG = 'CUSTOM_FUEL_CARD_CONFIG';
export const CUSTOM_LOADBOARD_CONFIG = 'CUSTOM_LOADBOARD_CONFIG';
export const CUSTOM_PUBLIC_CLO_CONFIG = 'CUSTOM_PUBLIC_CLO_CONFIG';
export const CUSTOM_VIN_LOOKUP_CONFIG = 'CUSTOM_VIN_LOOKUP_CONFIG';
export const CUSTOM_CARRIER_RATE_CONFIG = 'CUSTOM_CARRIER_RATE_CONFIG';
export const CUSTOM_TOLL_CHARGES_CONFIG = 'CUSTOM_TOLL_CHARGES_CONFIG';
export const CUSTOM_EDI_EXPORTER_CONFIG = 'CUSTOM_EDI_EXPORTER_CONFIG';
export const CUSTOM_EDI_EXTRACTOR_CONFIG = 'CUSTOM_EDI_EXTRACTOR_CONFIG';
export const CUSTOM_MAIL_TEMPLATE_CONFIG = 'CUSTOM_MAIL_TEMPLATE_CONFIG';
export const CUSTOM_EXPENSE_TYPES_CONFIG = 'CUSTOM_EXPENSE_TYPES_CONFIG';
export const CUSTOM_GL_CODE_MAPPING_CONFIG = 'CUSTOM_GL_CODE_MAPPING_CONFIG';
export const CUSTOM_USER_MONITORING_CONFIG = 'CUSTOM_USER_MONITORING_CONFIG';
export const CUSTOM_ADVANCE_PAYMENT_CONFIG = 'CUSTOM_ADVANCE_PAYMENT_CONFIG';
export const CUSTOM_STATUS_MESSAGES_CONFIG = 'CUSTOM_STATUS_MESSAGES_CONFIG';
export const CUSTOM_GPS_INTEGRATION_CONFIG = 'CUSTOM_GPS_INTEGRATION_CONFIG';
export const CUSTOM_LOS_SUBSCRIPTION_CONFIG = 'CUSTOM_LOS_SUBSCRIPTION_CONFIG';
export const CUSTOM_LOS_PRICING_PLAN_CONFIG = 'CUSTOM_LOS_PRICING_PLAN_CONFIG';
export const CUSTOM_DOCUMENT_SENDING_CONFIG = 'CUSTOM_DOCUMENT_SENDING_CONFIG';
export const CUSTOM_ADD_NOTIFICATION_CONFIG = 'CUSTOM_ADD_NOTIFICATION_CONFIG';
export const CUSTOM_INTEGRATION_MAIL_CONFIG = 'CUSTOM_INTEGRATION_MAIL_CONFIG';
export const CUSTOM_EQUIPMENT_MAPPING_CONFIG = 'CUSTOM_EQUIPMENT_MAPPING_CONFIG';
export const CUSTOM_CARRIER_SCORECARD_CONFIG = 'CUSTOM_CARRIER_SCORECARD_CONFIG';
export const CUSTOM_CARRIER_EDI_LOADER_CONFIG = 'CUSTOM_CARRIER_EDI_LOADER_CONFIG';
export const CUSTOM_LOAD_CUSTOM_STATUS_CONFIG = 'CUSTOM_LOAD_CUSTOM_STATUS_CONFIG';
export const CUSTOM_TRUCK_TYPE_MAPPING_CONFIG = 'CUSTOM_TRUCK_TYPE_MAPPING_CONFIG';
export const CUSTOM_INTEGRATION_CONFIGS_CONFIG = 'CUSTOM_INTEGRATION_CONFIGS_CONFIG';
export const CUSTOM_STATUS_CODE_MAPPING_CONFIG = 'CUSTOM_STATUS_CODE_MAPPING_CONFIG';
export const CUSTOM_INVOICE_STATUS_RULE_CONFIG = 'CUSTOM_INVOICE_STATUS_RULE_CONFIG';
export const CUSTOM_CUSTOMER_ID_MAPPING_CONFIG = 'CUSTOM_CUSTOMER_ID_MAPPING_CONFIG';
export const CUSTOM_TRAILER_TYPE_MAPPING_CONFIG = 'CUSTOM_TRAILER_TYPE_MAPPING_CONFIG';
export const CUSTOM_CARRIER_EDI_EXPORTER_CONFIG = 'CUSTOM_CARRIER_EDI_EXPORTER_CONFIG';
export const CUSTOM_SERVICE_TYPE_MAPPING_CONFIG = 'CUSTOM_SERVICE_TYPE_MAPPING_CONFIG';
export const CUSTOM_DISPATCH_INTEGRATION_CONFIG = 'CUSTOM_DISPATCH_INTEGRATION_CONFIG';
export const CUSTOM_TERMINAL_INTEGRATION_CONFIG = 'CUSTOM_TERMINAL_INTEGRATION_CONFIG';
export const CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG = 'CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG';
export const CUSTOM_FACTORING_INTEGRATION_CONFIG = 'CUSTOM_FACTORING_INTEGRATION_CONFIG';
export const CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG = 'CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG';
export const CUSTOM_MARGIN_VIOLATION_RULE_CONFIG = 'CUSTOM_MARGIN_VIOLATION_RULE_CONFIG';
export const CUSTOM_INTEGRATION_ACCOUNTING_CONFIG = 'CUSTOM_INTEGRATION_ACCOUNTING_CONFIG';
export const CUSTOM_SERVICE_MAPPING_CONFIG_SECTION = 'CUSTOM_SERVICE_MAPPING_CONFIG_SECTION';
export const CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG = 'CUSTOM_CARRIER_SYNCHRONIZATION_CONFIG';
export const CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG = 'CUSTOM_CROSS_BORDER_INTEGRATION_CONFIG';
export const CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG = 'CUSTOM_CARRIER_PICKUP_REQUESTER_CONFIG';
export const CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG = 'CUSTOM_INTEGRATION_DOCUMENT_HUB_CONFIG';
export const CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG = 'CUSTOM_DEFAULT_ORDER_ACCESSORIAL_CONFIG';
export const CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG = 'CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG';
export const CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG = 'CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG';
export const CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG = 'CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG';
export const CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG = 'CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG';
export const CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG = 'CUSTOM_INTEGRATION_TRAILER_TRACKING_CONFIG';
export const CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG = 'CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG';
export const CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG = 'CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG';
export const CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG = 'CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG';
export const CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT = 'CUSTOM_ORDER_QUOTE_TERMS_AND_CONDITIONS_DOCUMENT';
export const CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG = 'CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG';

// task management
export const TASK_MANAGEMENT_CONFIG_GROUP = 'taskManagement';

export const TASK_MANAGEMENT_TASK_NUMBER_SEQUENCE = 'taskManagement_taskNumber_sequence';

export const CUSTOM_TASK_TYPE_CONFIG = 'taskType';
export const CUSTOM_TASK_STATUS_CONFIG = 'taskStatus';

// claim
export const CLAIM_CONFIG_GROUP = 'claim';

export const CLAIM_CLAIM_NUMBER_SEQUENCE = 'claim_claimNumber_sequence';
export const CLAIM_CLAIM_NUMBER_AUTOGENERATED = 'claim_claimNumber_autogenerated';

export const CLAIM_TYPE = 'claim_type';
export const CLAIM_STATUS = 'claim_status';
export const CLAIM_REASON = 'claim_reason';
export const CLAIM_SUB_STATUS = 'claim_sub_status';
export const CLAIM_ACCIDENT_TYPE = 'claim_accidentType';
export const CLAIM_DOCUMENT_TYPE = 'claim_documentType';
export const CLAIM_INVOLVED_PARTY_CONTACT_TYPE = 'claim_involvedPartyContactType';

// rate engine
export const RATE_ENGINE_CONFIG_GROUP = 'rateEngine';

export const RATE_ENGINE_MAX_DISTANCE = 'rateEngine_maxDistance';
export const RATE_ENGINE_USE_TRIP_MAX_PAY = 'rateEngine_useTripMaxPay';
export const RATE_ENGINE_AUTO_ASSIGN_TYPE = 'rateEngine_autoAssignType';
export const RATE_ENGINE_MAX_DISTANCE_UOM = 'rateEngine_maxDistance_uom';
export const RATE_ENGINE_MARGIN_VIOLATION_REASON = 'rateEngine_marginViolationReason';
export const RATE_ENGINE_MULTIPLE_CURRENCY_CHARGES = 'rateEngine_multipleCurrencyCharges';
export const RATE_ENGINE_SEPARATE_LINE_HAUL_FOR_EVERY_CLO = 'rateEngine_separateLineHaulForEveryClo';
export const RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_VALUE = 'rateEngine_cloRateAdjustmentForTel_value';
export const RATE_ENGINE_CLO_RATE_ADJUSTMENT_FOR_TEL_RATE_UNIT = 'rateEngine_cloRateAdjustmentForTel_rateUnit';

// invoice
export const INVOICE_CONFIG_GROUP = 'invoice';
// invoice gl code
export const INVOICE_GL_CODE = 'invoice_gl_code';
// invoice qb
export const INVOICE_QB_QB_ACCOUNT = 'invoice_qb_qb_account';
export const INVOICE_QB_OVERRIDE_URL = 'invoice_qb_overrideUrl';
export const INVOICE_QB_AUTHORIZATION = 'invoice_qb_authorization';
export const INVOICE_QB_PAYMENT_SYNCHRONIZATION = 'invoice_qb_paymentSynchronization';
export const INVOICE_QB_QB_VENDOR_TRANSACTION_TYPE = 'invoice_qb_qb_vendor_transactionType';
export const INVOICE_QB_QB_VENDOR_EXPENSE_PAYMENT_METHOD = 'invoice_qb_qb_vendor_expense_payment_method';
// invoice sage intacct
export const INVOICE_SAGE_ACCOUNT = 'invoice_sage_sage_account';
export const INVOICE_SAGE_VENDOR_IDENTIFIER = 'invoice_sage_vendorIdentifier';
export const INVOICE_SAGE_BILL_ITEM_LOCATION = 'invoice_sage_billItemLocation';
export const INVOICE_SAGE_CARRIER_IDENTIFIER = 'invoice_sage_carrierIdentifier';
export const INVOICE_SAGE_CUSTOMER_IDENTIFIER = 'invoice_sage_customerIdentifier';
export const INVOICE_SAGE_INVOICE_ITEM_LOCATION = 'invoice_sage_invoiceItemLocation';
export const INVOICE_SAGE_SEND_AS_ORDER = 'invoice_sage_sendAsOrder';
export const INVOICE_SAGE_INVOICE_ITEM_UNIT = 'invoice_sage_invoiceItemUnit';
export const INVOICE_SAGE_ORDER_TRANSACTION = 'invoice_sage_orderTransaction';
// invoice business cental
export const INVOICE_BUSINESS_CENTRAL_VENDOR_IDENTIFIER = 'invoice_businessCentral_vendorIdentifier';
export const INVOICE_BUSINESS_CENTRAL_CARRIER_IDENTIFIER = 'invoice_businessCentral_carrierIdentifier';
export const INVOICE_BUSINESS_CENTRAL_SERVICE_VENDOR_IDENTIFIER = 'invoice_businessCentral_serviceVendorIdentifier';
// invoice qb line haul
export const INVOICE_QB_DRIVER_LINE_HAUL_ACCOUNT_NAME = 'invoice_qb_driver_lineHaul_accountName';
export const INVOICE_QB_VENDOR_LINE_HAUL_ACCOUNT_NAME = 'invoice_qb_vendor_lineHaul_accountName';
export const INVOICE_QB_CARRIER_LINE_HAUL_ACCOUNT_NAME = 'invoice_qb_carrier_lineHaul_accountName';
export const INVOICE_QB_CUSTOMER_LINE_HAUL_ACCOUNT_NAME = 'invoice_qb_customer_lineHaul_accountName';
// invoice qb carrier
export const INVOICE_QB_CARRIER_IDENTIFIER = 'invoice_qb_carrierIdentifier';
export const INVOICE_QB_CARRIER_OVERRIDE_URL = 'invoice_qb_carrier_overrideUrl';
export const INVOICE_QB_CARRIER_TRANSACTION_TYPE = 'invoice_qb_carrier_transactionType';
export const INVOICE_QB_CARRIER_ADDRESS_IDENTIFIER = 'invoice_qb_carrierAddressIdentifier';
export const INVOICE_QB_CARRIER_PAYABLE_ACCOUNT_NAME = 'invoice_qb_carrier_payable_accountName';
export const INVOICE_QB_CARRIER_EXPENSE_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_carrier_expenseDistributionLine_accountName';
// invoice qb vendor
export const INVOICE_QB_VENDOR_VENDOR_IDENTIFIER = 'invoice_qb_vendorIdentifier';
export const INVOICE_QB_VENDOR_TRANSACTION_TYPE = 'invoice_qb_vendor_transactionType';
export const INVOICE_QB_VENDOR_PAYABLE_ACCOUNT_NAME = 'invoice_qb_vendor_payable_accountName';
export const INVOICE_QB_VENDOR_EXPENSE_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_vendor_expenseDistributionLine_accountName';
// invoice qb customer
export const INVOICE_QB_CUSTOMER_IDENTIFIER = 'invoice_qb_customerIdentifier';
export const INVOICE_QB_CUSTOMER_OVERRIDE_URL = 'invoice_qb_customer_overrideUrl';
export const INVOICE_QB_CUSTOMER_RECEIVABLE_ACCOUNT_NAME = 'invoice_qb_customer_receivable_accountName';
export const INVOICE_QB_CUSTOMER_INCOME_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_customer_incomeDistributionLine_accountName';
// invoice qb driver
export const INVOICE_QB_DRIVER_TRANSACTION_TYPE = 'invoice_qb_driver_transactionType';
export const INVOICE_QB_CONTRACTOR_TRANSACTION_TYPE = 'invoice_qb_contractor_transactionType';
export const INVOICE_QB_DRIVER_PAYABLE_ACCOUNT_NAME = 'invoice_qb_driver_payable_accountName';
export const INVOICE_QB_DRIVER_EXPENSE_PAYMENT_METHOD = 'invoice_qb_driver_expense_payment_method';
export const INVOICE_QB_OWNER_OPERATOR_TRANSACTION_TYPE = 'invoice_qb_ownerOperator_transactionType';
export const INVOICE_QB_CONTRACTOR_PAYABLE_ACCOUNT_NAME = 'invoice_qb_contractor_payable_accountName';
export const INVOICE_QB_OWNER_OPERATOR_PAYABLE_ACCOUNT_NAME = 'invoice_qb_ownerOperator_payable_accountName';
export const INVOICE_QB_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_contractor_expenseDistributionLine_accountName';
export const INVOICE_QB_DRIVER_EXPENSE_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_driver_expenseDistributionLine_accountName';
export const INVOICE_QB_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE_ACCOUNT_NAME =
  'invoice_qb_ownerOperator_expenseDistributionLine_accountName';
// invoice qb service vendor
export const INVOICE_QB_SERVICE_VENDOR_TRANSACTION_TYPE = 'invoice_qb_serviceVendor_transactionType';
// clo
export const INVOICE_CLO_INVOICE_EXPORT = 'invoice_clo_invoice_exportAllowed';
export const INVOICE_CLO_INVOICE_NUMBER_SEQUENCE = 'invoice_clo_invoiceNumber_sequence';
export const INVOICE_CLO_CUSTOMER_INVOICE_ADD_DAYS = 'invoice_clo_customerInvoice_addDays';
export const INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED = 'invoice_clo_invoiceNumber_autogenerated';
export const INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_DATE = 'invoice_clo_customerInvoice_defaultDate';
export const INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_ON = 'invoice_clo_customerInvoice_autoCreateOn';
export const INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS = 'invoice_clo_customerInvoice_defaultStatus';
export const INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_TEXT = 'invoice_clo_customerInvoice_massMail_text';
export const INVOICE_CLO_CUSTOMER_INVOICE_MARK_AS_READY = 'invoice_clo_customerInvoice_shownMarkAsReady';
export const INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS = 'invoice_clo_customerInvoice_defaultNetDays';
export const INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_SUBJECT = 'invoice_clo_customerInvoice_massMail_subject';
export const INVOICE_CLO_INVOICE_NUMBER_USE_CLO_PRIM_REF = 'invoice_clo_invoiceNumber_useCloPrimaryReference';
export const INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS = 'invoice_clo_secondCustomerInvoice_defaultStatus';
export const INVOICE_CLO_CUSTOMER_INVOICE_ADD_TO_CREDIT_ON_CLO_CANCEL_STATUS =
  'invoice_clo_customerInvoice_addToCreditOnCloCancelStatus';
export const INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_CARRIER_INVOICE_STATUSES =
  'invoice_clo_customerInvoice_autoCreate_carrierInvoiceStatuses';
export const INVOICE_CLO_CUSTOMER_INVOICE_INTEGRATED_REFERENCE =
  'invoice_clo_customerInvoice_integratedCustomerReference';
export const INVOICE_CLO_CUSTOMER_INVOICE_AUTO_DROP_REQUIRED_DOCUMENT_TYPES =
  'invoice_clo_customerInvoice_autoCreate_dropRequiredDocumentTypes';
export const INVOICE_CLO_CUSTOMER_INVOICE_AUTO_PICKUP_REQUIRED_DOCUMENT_TYPES =
  'invoice_clo_customerInvoice_autoCreate_pickupRequiredDocumentTypes';
export const INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CLO_REQUIRED_DOCUMENT_TYPES =
  'invoice_clo_customerInvoice_autoCreate_cloRequiredDocumentTypes';
export const INVOICE_CLO_CUSTOMER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES =
  'invoice_clo_customerInvoice_costAllocation_skippedStatuses';
export const INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_COMPLETED_STATUS =
  'invoice_clo_customerInvoice_defaultCompletedStatus';
// clo master invoice
export const INVOICE_CLO_MASTER_INVOICE_MAIL_TEXT = 'invoice_clo_masterInvoice_mail_text';
export const INVOICE_CLO_MASTER_INVOICE_MAIL_SUBJECT = 'invoice_clo_masterInvoice_mail_subject';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS = 'invoice_clo_masterInvoice_defaultStatus';
export const INVOICE_CLO_MASTER_INVOICE_DATE_RANGE_DAYS = 'invoice_clo_masterInvoice_dateRangeDays';
export const INVOICE_CLO_MASTER_INVOICE_NUMBER_SEQUENCE = 'invoice_clo_masterInvoiceNumber_sequence';
export const INVOICE_CLO_CUSTOMER_INVOICE_LOCK_STATUSES = 'invoice_clo_customerInvoice_lockStatuses';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_GROUP_BY = 'invoice_clo_masterInvoice_defaultGroupBy';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_REPLY_TO = 'invoice_clo_masterInvoice_defaultReplyTo';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_TEMPLATE = 'invoice_clo_masterInvoice_defaultTemplate';
// invoice_clo_masterInvoice_mail_text
export const INVOICE_CLO_MASTER_INVOICE_GROUP_BY_REFERENCE = 'invoice_clo_masterInvoice_groupByReference';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_SEND_EMAILS = 'invoice_clo_masterInvoice_defaultSendEmails';
export const INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED = 'invoice_clo_masterInvoiceNumber_autogenerated';
export const INVOICE_CLO_MASTER_INVOICE_ADDITIONAL_REFERENCES = 'invoice_clo_masterInvoice_additionalReferences';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_FACTORING_NAME = 'invoice_clo_masterInvoice_defaultFactoringName';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES =
  'invoice_clo_masterInvoice_defaultAttachmentDocumentTypes';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_INVOICE_PAYMENT_TERMS =
  'invoice_clo_masterInvoice_defaultInvoicePaymentTerms';
export const INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS =
  'invoice_clo_masterInvoice_invoiceFilterDateRangeDays';
export const INVOICE_CLO_MASTER_INVOICE_DEFAULT_CARRIER_DOCUMENT_TYPES =
  'invoice_clo_masterInvoice_defaultCarrierDocumentTypes';
// tel
export const INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY = 'invoice_tel_separatePaymentByCurrency';
// driver
export const INVOICE_TEL_DRIVER_INVOICE_ADD_DAYS = 'invoice_tel_driverInvoice_addDays';
export const INVOICE_TEL_DRIVER_INVOICE_DEFAULT_DATE = 'invoice_tel_driverInvoice_defaultDate';
export const INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_ON = 'invoice_tel_driverInvoice_autoCreateOn';
export const INVOICE_TEL_DRIVER_INVOICE_DEFAULT_STATUS = 'invoice_tel_driverInvoice_defaultStatus';
export const INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE = 'invoice_tel_driverInvoiceNumber_sequence';
export const INVOICE_TEL_DRIVER_INVOICE_DEFAULT_NET_DAYS = 'invoice_tel_driverInvoice_defaultNetDays';
export const INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED = 'invoice_tel_driverInvoiceNumber_autogenerated';
export const INVOICE_TEL_SECOND_DRIVER_INVOICE_DEFAULT_STATUS = 'invoice_tel_secondDriverInvoice_defaultStatus';
export const INVOICE_TEL_DRIVER_INVOICE_DEFAULT_COMPLETED_STATUS = 'invoice_tel_driverInvoice_defaultCompletedStatus';
export const INVOICE_TEL_DRIVER_INVOICE_NUMBER_USE_TEL_PRIM_REF =
  'invoice_tel_driverInvoiceNumber_useTelPrimaryReference';
export const INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_INVOICE_WITH_ZERO_RATE =
  'invoice_tel_driverInvoice_autoCreateInvoiceWithZeroRate';
// driver payroll
export const INVOICE_TEL_PAYROLL_DAY_TO = 'invoice_tel_payroll_dayTo';
export const INVOICE_TEL_PAYROLL_DAY_FROM = 'invoice_tel_payroll_dayFrom';
export const INVOICE_TEL_PAYROLL_MAIL_TEXT = 'invoice_tel_payroll_mail_text';
export const INVOICE_TEL_PAYROLL_MAIL_SUBJECT = 'invoice_tel_payroll_mail_subject';
export const INVOICE_TEL_PAYROLL_MAIL_REPLY_TO = 'invoice_tel_payroll_mail_replyTo';
export const INVOICE_TEL_PAYROLL_DEFAULT_STATUS = 'invoice_tel_payroll_defaultStatus';
export const INVOICE_TEL_PAYROLL_DATE_RANGE_DAYS = 'invoice_tel_payroll_dateRangeDays';
export const INVOICE_TEL_PAYROLL_NUMBER_SEQUENCE = 'invoice_tel_payrollNumber_sequence';
export const INVOICE_TEL_DRIVER_INVOICE_EXPORT = 'invoice_tel_driverInvoice_exportAllowed';
export const INVOICE_TEL_DRIVER_INVOICE_LOCK_STATUSES = 'invoice_tel_driverInvoice_lockStatuses';
export const INVOICE_TEL_PAYROLL_NUMBER_AUTOGENERATED = 'invoice_tel_payrollNumber_autogenerated';
export const INVOICE_TEL_PAYROLL_DEFAULT_INVOICE_STATUSES = 'invoice_tel_payroll_defaultInvoiceStatuses';
export const INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION = 'invoice_tel_payroll_allowDeletePaidDeduction';
export const INVOICE_TEL_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT = 'invoice_tel_payroll_addPaymentDetailsComment';
export const INVOICE_TEL_PAYROLL_ALLOW_CREATE_EMPTY_PAYROLLS = 'invoice_tel_payroll_allowCreateEmptyPayrolls';
export const INVOICE_TEL_PAYROLL_INVOICE_FILTER_DATE_RANGE_DAYS = 'invoice_tel_payroll_invoiceFilterDateRangeDays';
export const INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION =
  'invoice_fleetVendor_payroll_allowDeletePaidDeduction';
export const INVOICE_TEL_DRIVER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES =
  'invoice_tel_driverInvoice_costAllocation_skippedStatuses';
// vendor payroll
export const INVOICE_FLEET_VENDOR_PAYROLL_MAIL_TEXT = 'invoice_fleetVendor_payroll_mail_text';
export const INVOICE_FLEET_VENDOR_PAYROLL_MAIL_SUBJECT = 'invoice_fleetVendor_payroll_mail_subject';
export const INVOICE_FLEET_VENDOR_PAYROLL_MAIL_REPLY_TO = 'invoice_fleetVendor_payroll_mail_replyTo';
export const INVOICE_FLEET_VENDOR_PAYROLL_DEFAULT_STATUS = 'invoice_fleetVendor_payroll_defaultStatus';
export const INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_SEQUENCE = 'invoice_fleetVendor_payrollNumber_sequence';
export const INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_AUTOGENERATED = 'invoice_fleetVendor_payrollNumber_autogenerated';
export const INVOICE_FLEET_VENDOR_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT =
  'invoice_fleetVendor_payroll_addPaymentDetailsComment';
// carrier
export const AUTO_STATUS_CHECK_ENABLED = 'autoStatusCheckEnabled';
export const INVOICE_TEL_CARRIER_INVOICE_ADD_DAYS = 'invoice_tel_carrierInvoice_addDays';
export const INVOICE_TEL_CARRIER_INVOICE_DEFAULT_DATE = 'invoice_tel_carrierInvoice_defaultDate';
export const INVOICE_TEL_CARRIER_INVOICE_LOCK_STATUSES = 'invoice_tel_carrierInvoice_lockStatuses';
export const INVOICE_TEL_CARRIER_INVOICE_AUTO_CREATE_ON = 'invoice_tel_carrierInvoice_autoCreateOn';
export const INVOICE_TEL_CARRIER_INVOICE_DEFAULT_STATUS = 'invoice_tel_carrierInvoice_defaultStatus';
export const INVOICE_TEL_CARRIER_INVOICE_NUMBER_SEQUENCE = 'invoice_tel_carrierInvoiceNumber_sequence';
export const INVOICE_TEL_CARRIER_INVOICE_DEFAULT_NET_DAYS = 'invoice_tel_carrierInvoice_defaultNetDays';
export const INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED = 'invoice_tel_carrierInvoiceNumber_autogenerated';
export const INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_STATUS = 'invoice_tel_secondCarrierInvoice_defaultStatus';
export const INVOICE_TEL_CARRIER_INVOICE_NUMBER_USE_TEL_PRIM_REF =
  'invoice_tel_carrierInvoiceNumber_useTelPrimaryReference';
export const INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_COMPLETED_STATUS =
  'invoice_tel_carrierInvoice_defaultCompletedStatus';
export const INVOICE_TEL_CARRIER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES =
  'invoice_tel_carrierInvoice_costAllocation_skippedStatuses';
// service vendor
export const INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_SEQUENCE =
  'invoice_tel_serviceVendorInvoiceNumber_sequence';
export const INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_AUTOGENERATED =
  'invoice_tel_serviceVendorInvoiceNumber_autogenerated';
// custom
export const INVOICE_DP_STATUS = 'invoice_driverPayroll_status';
export const INVOICE_FI_STATUS = 'invoice_fleetInvoice_status';
export const INVOICE_CI_STATUS = 'invoice_customerInvoice_status';
export const INVOICE_CARRIER_STATUS = 'invoice_carrierInvoice_status';
export const INVOICE_CARRIER_REJECT_REASON = 'invoice_tel_carrierInvoice_rejectReason';
export const INVOICE_CARRIER_APPROVE_REASON = 'invoice_tel_carrierInvoice_approveReason';
export const INVOICE_FLEET_SERVICE_INVOICE_STATUS = 'INVOICE_FLEET_SERVICE_INVOICE_STATUS';

// service vendor
export const SERVICE_VENDOR_CONFIG_GROUP = 'serviceVendor';

export const SERVICE_VENDOR_DOCUMENT_TYPE = 'serviceVendor_documentType';
export const SERVICE_VENDOR_VENDOR_SERVICE_TYPE = 'serviceVendor_vendorServiceType';
export const SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_SEQUENCE = 'serviceVendor_accountingExternalId_sequence';
export const SERVICE_VENDOR_ACCOUNTING_EXTERNAL_ID_AUTOGENERATED = 'serviceVendor_accountingExternalId_autogenerated';

// ui
export const UI_CONFIG_GROUP = 'ui';

export const UI_HIDDEN_TABS = 'ui_hiddenTabs';
export const UI_USE_MUI_CALENDAR = 'ui_useMuiCalendar';
export const UI_HIDDEN_MENU_ITEMS = 'ui_hiddenMenuItems';
export const UI_USE_MENU_VERSION_2 = 'ui_useMenuVersion2';
export const UI_OPEN_LOAD_IN_SIDEBAR = 'ui_openLoadInSidebar';
export const UI_ROUTE_LIST_QUOTES_SHOW_TAB = 'ui_show_quotes_tab';
export const UI_USE_NEW_DISPATCH_BOARD = 'ui_useNewDispatchBoard';
export const UI_CALENDAR_TIME_INTERVAL = 'ui_calendarTimeInterval';
export const UI_USE_NEW_FLEET_PROFILE = 'ui_use_new_fleet_profile';
export const UI_USE_NEW_CARRIER_PROFILE = 'ui_useNewCarrierProfile';
export const UI_CHART_COLLECTIONS_TYPE = 'ui_chart_collectionsType';
export const UI_TRACKING_USE_GOOGLE_MAP = 'ui_tracking_useGoogleMap';
export const UI_SHOW_FAST_LOAD_INDICATOR = 'ui_showFastLoadIndicator';
export const UI_REPLACE_DOLLAR_SYMBOL_WITH_US = 'ui_replaceDollarSymbolWithUS';
export const UI_ORDER_TRIP_DEFAULT_NOTES_CARD = 'ui_orderTrip_defaultNotesCard';
export const UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP = 'ui_customerPortal_useGoogleMap';
export const UI_SHOW_LOCATION_INSTEAD_LATE_DATE = 'ui_showLocationInsteadLateDate';
export const UI_LOAD_PLANNER_HIDE_INBOUND_OUTBOUND_TABS = 'ui_loadPlanner_hideInboundOutboundTabs';
// loadboard
export const UI_LOADBOARD_AUTODIALMODAL = 'ui_loadboard_autodialModal';
export const UI_LOADBOARD_AUTODIALACTION = 'ui_loadboard_autodialAction';
export const UI_LOADBOARD_HIDE_TRIP_STOPS = 'ui_loadboard_hideTripStops';
export const UI_LOADBOARD_AUTODIALAPPNAME = 'ui_loadboard_autodialAppName';
// route list
export const UI_ROUTE_LIST_GENERAL_DEFAULT_LOAD = 'ui_routeList_general_defaultLoad';
export const UI_ROUTE_LIST_TEL_DEFAULT_ORGANIZE_BY = 'ui_routeList_tel_defaultGroupBy';
export const UI_ROUTE_LIST_CLO_DEFAULT_ORGANIZE_BY = 'ui_routeList_clo_defaultGroupBy';
export const UI_ROUTE_LIST_SHOW_TRANSPORTATION_MODE = 'ui_routeList_show_transportationMode';
export const UI_ROUTE_LIST_TEL_SECONDARY_REFERENCE_TYPE = 'ui_routeList_tel_secondary_reference_type';
export const UI_ROUTE_LIST_CLO_SECONDARY_REFERENCE_TYPE = 'ui_routeList_clo_secondary_reference_type';
// driver cards
export const UI_DRIVER_CARDS_ZOOM = 'ui_driverCards_zoom';
export const UI_DRIVER_CARDS_24H_VIEW = 'ui_driverCards_24hView';
export const UI_DRIVER_CARDS_CARD_VIEW = 'ui_driverCards_cardView';
export const UI_DRIVER_CARDS_CARD_HEIGHT = 'ui_driverCards_cardHeight';
export const UI_DRIVER_CARDS_WARNING_PERIOD = 'ui_driverCards_warningPeriod';
export const UI_DRIVER_CARDS_SHOW_TEAM_DRIVER = 'ui_driverCards_showTeamDriver';
export const UI_DRIVER_CARDS_DEFAULT_GROUP_BY = 'ui_driverCards_defaultGroupBy';
export const UI_DRIVER_CARDS_DEFAULT_DAY_FILTER = 'ui_driverCards_defaultDayFilter';
export const UI_DRIVER_CARDS_DEFAULT_GROUP_FILTER = 'ui_driverCards_defaultGroupFilter';
export const UI_DRIVER_CARDS_DEFAULT_DRIVER_FILTER = 'ui_driverCards_defaultDriverFilter';
export const UI_DRIVER_CARDS_DEFAULT_SEARCH_RADIUS = 'ui_driverCards_default_searchRadius';
export const UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS = 'ui_driverCards_default_minimumEmptyHours';
export const UI_DRIVER_CARDS_FUTURE_EMPTY_WARNING_PERIOD = 'ui_driverCards_futureEmptyWarningPeriod';
// order
export const UI_ORDER_ORDER_ENTRY = 'ui_order_orderEntry';
// tel
export const UI_TEL_CARRIER_RATE_SHOW_LANE_HISTORY = 'ui_tel_carrierRate_showLaneHistory';
// available drivers
export const UI_AVAILABLE_DRIVERS_DEFAULT_TRUCK_TYPES = 'ui_availableDrivers_defaultTruckTypes';
export const UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_RADIUS = 'ui_availableDrivers_defaultSearchRadius';
export const UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_LOCATION = 'ui_availableDrivers_defaultSearchLocation';

// carrier
export const CARRIER_CONFIG_GROUP = 'carrier';

export const CARRIER_DOCUMENT_TYPE = 'carrier_documentType';
export const CARRIER_INSURANCE_TYPE = 'carrier_insuranceType';
export const CARRIER_REQUIRED_FIELDS = 'carrier_requiredFields';
export const CARRIER_CREATE_ON_BRANCH = 'carrier_createOnBranch';
export const CARRIER_DEACTIVATED_TYPE = 'carrier_deactivatedReason';
export const CARRIER_ALLOW_EDITING_FOR_DIVISIONS = 'carrier_allowEditingForDivisions';
// general
export const CARRIER_GENERAL_USE_FLEET_TRAILER = 'carrier_general_useFleetTrailer';
export const CARRIER_GENERAL_SHOWN_DOCUMENT_TYPES = 'carrier_general_shownDocumentTypes';
export const CARRIER_GENERAL_SHOWN_TRIP_REFERENCES = 'carrier_general_shownTripReferences';
export const CARRIER_GENERAL_SAFER_WATCH_SYNC_REQUIRED = 'carrier_general_saferWatchSyncRequired';
// carrier quote process
export const CARRIER_EMAIL_GROUP = 'carrier_emailGroup';
export const CARRIER_QUOTE_PROCESS_DEFAULT_SUBJECT = 'carrier_quoteProcess_defaultSubject';
export const CARRIER_QUOTE_PROCESS_DEFAULT_MESSAGE = 'carrier_quoteProcess_defaultMessage';
export const CARRIER_QUOTE_PROCESS_DECLINE_REASON_CODE = 'carrier_quoteProcess_declineReasonCode';
export const CARRIER_QUOTE_PROCESS_EMAIL_DIVISION_NAME = 'carrier_quoteProcess_emailDivisionName';
// carrier status check
export const CARRIER_LOAD_STATUS_ENABLED = 'carrier_loadStatusCheck_enabled';
export const CARRIER_LOAD_STATUS_SUBJECT = 'carrier_loadStatusCheck_subject';
export const CARRIER_LOAD_STATUS_MESSAGE = 'carrier_loadStatusCheck_message';
export const CARRIER_LOAD_STATUS_FREQUENCY = 'carrier_loadStatusCheck_frequency';
export const CARRIER_LOAD_STATUS_AUTO_STATUS_CHECK_ENABLED = 'carrier_loadStatusCheck_autoStatusCheckEnabled';
export const CARRIER_CARRIER_STATUS_CHECK_DEFAULT_PICKUP_STATUS_CODE = 'carrier_statusCheck_defaultPickupStatusCode';
export const CARRIER_CARRIER_STATUS_CHECK_DEFAULT_DELIVERY_STATUS_CODE =
  'carrier_statusCheck_defaultDeliveryStatusCode';
export const CARRIER_LOAD_STATUS_CHECK_API_HOURS_BEFORE_FIRST_EVENT =
  'carrier_loadStatusCheck_api_hoursBeforeFirstEvent';
// carrier dispatch process
export const CARRIER_DISPATCH_PROCESS_SUBJECT = 'carrier_dispatchProcess_subject';
export const CARRIER_DISPATCH_PROCESS_MESSAGE = 'carrier_dispatchProcess_message';
export const CARRIER_DISPATCH_PROCESS_REPLY_TO = 'carrier_dispatchProcess_replyTo';
export const CARRIER_DISPATCH_PROCESS_AUTO_ACCEPT = 'carrier_dispatchProcess_autoAccept';
export const CARRIER_DISPATCH_PROCESS_AUTO_UPDATE_PICKUP_DATE = 'carrier_dispatchProcess_autoupdatePickupDate';
export const CARRIER_DISPATCH_PROCESS_EDI_VALIDATE_PICKUP_DATE = 'carrier_dispatchProcess_edi_validatePickupDate';
export const CARRIER_DISPATCH_PROCESS_API_VALIDATE_PICKUP_DATE = 'carrier_dispatchProcess_api_validatePickupDate';
export const CARRIER_DISPATCH_PROCESS_DEFAULT_DOCUMENT_TEMPLATE = 'carrier_dispatchProcess_defaultDocumentTemplate';
export const CARRIER_DISPATCH_PROCESS_EMAIL_VALIDATE_PICKUP_DATE = 'carrier_dispatchProcess_email_validatePickupDate';
export const CARRIER_DISPATCH_PROCESS_INTERNAL_VALIDATE_PICKUP_DATE =
  'carrier_dispatchProcess_internal_validatePickupDate';
export const CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_WORKING_HOURS_END_HOUR =
  'carrier_dispatchProcess_validatePickupDate_workingHoursEndHour';
export const CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_WORKING_HOURS_START_HOUR =
  'carrier_dispatchProcess_validatePickupDate_workingHoursStartHour';
export const CARRIER_DISPATCH_PROCESS_AUTO_UPDATE_PICKUP_DATE_MAX_DAYS =
  'carrier_dispatchProcess_autoupdatePickupDate_maxDays';
export const CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_MIN_HOURS =
  'carrier_dispatchProcess_validatePickupDate_minHours';
export const CARRIER_DISPATCH_PROCESS_AUTO_DISPATCH_ON_ORDER_CREATE =
  'carrier_dispatchProcess_autoDispatchOnOrderCreate';
export const CARRIER_DISPATCH_PROCESS_AUTO_DISPATCH_ON_ORDER_CREATE_FROM_TEMPLATE =
  'carrier_dispatchProcess_autoDispatchOnOrderCreateFromTemplate';
// carrier accept rate
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELDS = 'carrier_acceptRate_requiredFields';
export const CARRIER_EMAIL_TEMPLATE_SHOWN_FIELDS = 'carrier_emailTemplate_shownFields';
export const CARRIER_ACCEPT_RATE_LOCK_POPULATED_FIELDS = 'carrier_acceptRate_lockPopulatedFields';
export const CARRIER_EMAIL_TEMPLATE_SHOWN_FULL_ADDRESS = 'carrier_emailTemplate_shownFullAddress';
export const CARRIER_EMAIL_TEMPLATE_SHOW_RATE_BREAKDOWN = 'carrier_emailTemplate_shownRateBreakdown';
export const CARRIER_EMAIL_TEMPLATE_SHOW_UPDATE_LINK_IN_BOOKED_EMAIL =
  'carrier_emailTemplate_shownUpdateLinkInBookedEmail';
// carrier deactivation rule
export const CARRIER_DEFAULT_DEACTIVATION_RULE_STATISTIC_PERIOD =
  'carrier_defaultDeactivationRule_statisticPeriod';
export const CARRIER_DEFAULT_DEACTIVATION_RULE_STATISTIC_MINIMUM_LOAD_COUNT =
  'carrier_defaultDeactivationRule_statisticMinimumLoadCount';
// custom
export const CARRIER_DEACTIVATION_RULES = 'CARRIER_DEACTIVATION_RULES';
export const CARRIER_ONBOARDING_PACKAGE = 'CARRIER_ONBOARDING_PACKAGE';
export const CARRIER_ONBOARDING_PACKAGE_DOCUMENT = 'CARRIER_ONBOARDING_PACKAGE_DOCUMENT';
export const CARRIER_TERMS_AND_CONDITIONS_DOCUMENT = 'CARRIER_TERMS_AND_CONDITIONS_DOCUMENT';
// integration
export const INTEGRATION_CONFIG_GROUP = 'integration';
export const INTEGRATION_GPS_TYPE = 'integration_gpsIntegration_type';
export const INTEGRATION_BUSINESS_CENTRAL_DIVISION_NAME = 'integration_businessCentral_divisionName';
export const INTEGRATION_TRACKING_SYSTEM_DEFAULT_CONFIG = 'integration_trackingSystem_defaultConfig';
export const INTEGRATION_GPS_AUTO_ASSIGMENT_MANAGEMENT = 'integration_gpsIntegration_autoAssigmentManagement';
// integration bosch statistic
export const INTEGRATION_LOS_ACCOUNT_LINKED = 'integration_los_accountLinked';
export const INTEGRATION_BOSCH_STATISTIC_LAST_DAYS = 'integration_boschStatistic_lastDays';
export const INTEGRATION_BOSCH_STATISTIC_NEGATIVE_INVOICE_STATUSES =
  'integration_boschStatistic_negativeInvoiceStatuses';
// samsara
export const INTEGRATION_SAMSARA_GROUP_ID = 'integration_samsara_groupId';
export const INTEGRATION_SAMSARA_ACCESS_TOKEN = 'integration_samsara_accessToken';
// keep truckin
export const INTEGRATION_KEEP_TRUCKIN_API_KEY = 'integration_keepTruckin_apiKey';
// load board
export const INTEGRATION_LOADBOARD_TYPE = 'type';
export const INTEGRATION_LOADBOARD_ACCOUNT_ID = 'accountId';
export const INTEGRATION_LOADBOARD_SHOW_SHIPPER = 'showShipper';
export const INTEGRATION_LOADBOARD_TRANSPORTATION_MODE_GUID = 'transportationModeGuid';
// toll charges
export const INTEGRATION_TOLL_CHARGES_ACCOUNT_ID = 'accountId';
export const INTEGRATION_TOLL_CHARGES_SECURITY_KEY = 'securityKey';
// customer integration configs
export const INTEGRATION_CUSTOMER_INTEGRATION_INTEGRATION_CONFIG = 'integration_customerIntegration_integrationConfig';
export const INTEGRATION_CUSTOMER_INTEGRATION_STATUS_MESSAGE_ENABLED =
  'integration_customerIntegration_statusMessage_enabled';
// saferwatch
export const INTEGRATION_SAFER_WATCH_CUSTOMER_KEY = 'integration_saferWatch_customerKey';
// default integration status code
export const INTEGRATION_DEFAULT_STATUS_CODE = 'integration_defaultStatusCode';
export const INTEGRATION_DEFAULT_TRACKING_STATUS_CODE = 'integration_defaultTrackingStatusCode';
// Smartlook
export const INTEGRATION_SMARTLOOK_CUSTOMER_KEY = 'integration_smartlook_customerKey';

// AVAILABLE DOCUMENT TYPES
export const AVAILABLE_DOCUMENT_TYPES = 'availableDocumentTypes';

// mobile app
export const MOBILEAPP_CONFIG_GROUP = 'mobileApp';
export const MOBILEAPP_CONFIG_TAB_NAME_CARRIER = 'carrier';

// driver mobile app
export const MOBILEAPP_DRIVER_SHOWN_REFERENCES = 'mobileApp_driver_shownReferences';
export const MOBILEAPP_DRIVER_REQUIRED_TRAILER = 'mobileApp_driver_requiredTrailer';
export const MOBILEAPP_DRIVER_HIDE_DOCUMENT_BUTTONS = 'mobileApp_driver_hideDocumentButtons';
export const MOBILEAPP_DRIVER_VIN_CODE_SCAN_REQUIRED = 'mobileApp_driver_vinCode_scanRequired';
export const MOBILEAPP_DRIVER_DRIVER_PAYROLL_SHOWN_STATUSES = 'mobileApp_driver_driverPayroll_shownStatuses';
export const MOBILEAPP_DRIVER_VENDOR_PAYROLL_SHOWN_STATUSES = 'mobileApp_driver_vendorPayroll_shownStatuses';
//
export const MOBILEAPP_INSPECTION_REQUIRED_ON_LAST_DROP = 'mobileApp_driver_inspection_requiredOnLastDrop';
export const MOBILEAPP_INSPECTION_REQUIRED_ON_FIRST_PICKUP = 'mobileApp_driver_inspection_requiredOnFirstPickup';
// status code
export const MOBILEAPP_DRIVER_STATUS_CODE_STOP_CHECK_IN = 'mobileApp_driver_statusCode_stopCheckIn';
export const MOBILEAPP_DRIVER_STATUS_CODE_DROP_COMPLETE = 'mobileApp_driver_statusCode_dropComplete';
export const MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_COMPLETE = 'mobileApp_driver_statusCode_pickupComplete';
export const MOBILEAPP_DRIVER_STATUS_CODE_DROP_CHECK_IN = 'mobileApp_driver_statusCode_dropCheckIn';
export const MOBILEAPP_DRIVER_STATUS_CODE_PICKUP_CHECK_IN = 'mobileApp_driver_statusCode_pickupCheckIn';
// document types
export const MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_DROP = 'mobileApp_driver_defaultDocumentType_drop';
export const MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_PICKUP = 'mobileApp_driver_defaultDocumentType_pickUp';
export const MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_DROP = 'mobileApp_driver_requiredDocumentTypes_drop';
export const MOBILEAPP_DRIVER_DOCUMENT_TYPE_DEFAULT_GENERAL = 'mobileApp_driver_defaultDocumentType_general';
export const MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_PICKUP = 'mobileApp_driver_requiredDocumentTypes_pickUp';
export const MOBILEAPP_DRIVER_REQUIRED_DOCUMENT_TYPES_LAST_DROP = 'mobileApp_driver_requiredDocumentTypes_lastDrop';
// status check
export const MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_FREQUENCY = 'mobileApp_driver_loadStatusCheck_frequency';
export const MOBILEAPP_DRIVER_STATUS_CHECK_DEFAULT_STATUS_CODE = 'mobileApp_driver_statusCheck_defaultStatusCode';
export const MOBILEAPP_DRIVER_LOAD_STATUS_CHECK_AUTO_STATUS_CHECK_ENABLED =
  'mobileApp_driver_loadStatusCheck_autoStatusCheckEnabled';

// carrier mobile app
export const MOBILEAPP_CARRIER_COMPANY_NAME = 'mobileApp_carrier_companyName';
export const MOBILEAPP_CARRIER_HIDE_EVENT_TIME = 'mobileApp_carrier_hideEventTime';
export const MOBILEAPP_CARRIER_USE_FLEET_TRAILER = 'mobileApp_carrier_useFleetTrailer';
export const MOBILEAPP_CARRIER_SHOWN_DOCUMENT_TYPES = 'mobileApp_carrier_shownDocumentTypes';
export const MOBILEAPP_CARRIER_SHOWN_TRIP_REFERENCES = 'mobileApp_carrier_shownTripReferences';
export const MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_LAST_DROP = 'mobileApp_carrier_inspection_requiredOnLastDrop';
export const MOBILEAPP_CARRIER_INSPECTION_REQUIRED_ON_FIRST_PICKUP =
  'mobileApp_carrier_inspection_requiredOnFirstPickup';
// documents
export const MOBILEAPP_CARRIER_HIDE_DOCUMENT_BUTTONS = 'mobileApp_carrier_hideDocumentButtons';
export const MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_DROP = 'mobileApp_carrier_defaultDocumentType_drop';
export const MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_DROP = 'mobileApp_carrier_requiredDocumentTypes_drop';
export const MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_PICKUP = 'mobileApp_carrier_defaultDocumentType_pickUp';
export const MOBILEAPP_CARRIER_DEFAULT_DOCUMENT_TYPE_GENERAL = 'mobileApp_carrier_defaultDocumentType_general';
export const MOBILEAPP_CARRIER_REQUIRED_DOCUMENT_TYPE_PICKUP = 'mobileApp_carrier_requiredDocumentTypes_pickUp';
// status check
export const MOBILEAPP_CARRIER_LOAD_STATUS_SMS_ENABLED = 'mobileApp_carrier_loadStatusCheck_smsEnabled';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_FREQUENCY = 'mobileApp_carrier_loadStatusCheck_frequency';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_DROP_COMPLETE = 'mobileApp_carrier_loadStatusCheck_dropStatusCode';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_STOP_CHECK_IN = 'mobileApp_carrier_loadStatusCheck_checkInStatusCode';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_PICKUP_COMPLETE = 'mobileApp_carrier_loadStatusCheck_pickupStatusCode';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_AUTO_ENABLED =
  'mobileApp_carrier_loadStatusCheck_autoStatusCheckEnabled';
export const MOBILEAPP_CARRIER_LOAD_STATUS_CHECK_TRUCKING_STATUS_CODE =
  'mobileApp_carrier_loadStatusCheck_trackingStatusCode';

export const GROUPED_CONFIGS = {
  LOAD_CONFIGS_ARR: [
    GENERAL_SERVICES,
    GENERAL_EQUIPMENTS,
    CLO_GENERAL_USE_CONTAINERS,
    CLO_GENERAL_EVENTS_INTERVAL,
    GENERAL_MODE_TRANSPORTATION,
    CLO_ITEM_USE_DIFFERENT_TYPES,
    GENERAL_ROUTE_NAME_AUTOGENERATED,
    TEL_PRIMARY_REFERENCE_AUTOGENERATED,
    GENERAL_TRANSPORTATION_SERVICE_TYPE,
    TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
    TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
  ],
  LOAD_CONFIGS_ARR_FOR_DISPATCH_DETAILS: [
    GENERAL_SERVICES,
    GENERAL_EQUIPMENTS,
    UI_SHOW_FAST_LOAD_INDICATOR,
    CLO_GENERAL_EVENTS_INTERVAL,
    CLO_ITEM_DAMAGE_REPORT_AREA,
    CLO_ITEM_DAMAGE_REPORT_TYPE,
    GENERAL_MODE_TRANSPORTATION,
    CLO_ITEM_USE_DIFFERENT_TYPES,
    CLO_ITEM_DAMAGE_REPORT_SEVERITY,
    UI_ORDER_TRIP_DEFAULT_NOTES_CARD,
    GENERAL_ROUTE_NAME_AUTOGENERATED,
    TEL_PRIMARY_REFERENCE_AUTOGENERATED,
    GENERAL_TRANSPORTATION_SERVICE_TYPE,
    TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
    CLO_RATE_SYNC_RATE_FROM_CUSTOMER_INVOICE,
    TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
  ],
};

export const ADD_RATE_CONFIGS_ARRAY = [
  GENERAL_MODE_TRANSPORTATION,
  TEL_DRIVER_RATE_DEFAULT_MODE,
  GENERAL_BRANCH_DEFAULT_CURRENCY,
  GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  RATE_ENGINE_MARGIN_VIOLATION_REASON,
  TEL_DRIVER_RATE_DEFAULT_SERVICE_TYPE,
];

export const ADD_CARRIER_RATE_CONFIGS_ARRAY = [
  GENERAL_MODE_TRANSPORTATION,
  TEL_CARRIER_RATE_DEFAULT_MODE,
  GENERAL_BRANCH_DEFAULT_CURRENCY,
  GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  RATE_ENGINE_MARGIN_VIOLATION_REASON,
  TEL_CARRIER_RATE_DEFAULT_SERVICE_TYPE,
];

export const ADD_CUSTOMER_RATE_ASYNC_CONFIGS_ARRAY = [
  GENERAL_MODE_TRANSPORTATION,
  CLO_GENERAL_RATE_SERVICE_TYPE,
  GENERAL_BRANCH_DEFAULT_CURRENCY,
  GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  CLO_GENERAL_RATE_TRANSPORTATION_MODE,
];

export const EDIT_RATE_CONFIGS_ARRAY = [
  GENERAL_MODE_TRANSPORTATION,
  GENERAL_TRANSPORTATION_SERVICE_TYPE,
  RATE_ENGINE_MARGIN_VIOLATION_REASON,
];
