import * as R from 'ramda';
import React, { isValidElement } from 'react';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../ui';
//////////////////////////////////////////////////

const blue = G.getTheme('colors.light.blue');
const greyMatterhorn = G.getTheme('colors.greyMatterhorn');

// TODO: add common props

export const EmailsInfoPair = ({ label, emails }: Object) => {
  if (G.isNilOrEmpty(emails)) return null;

  return (
    <Flex
      py='4px'
      fontSize={12}
      flexWrap='wrap'
      lineHeight={1.6}
      alignItems='flex-start'
    >
      <Box mr='5px' color={greyMatterhorn}>
        {label}:
      </Box>
      <Box>
        {
          emails.map((email: string, index: number) => (
            <StyledLink
              key={index}
              color={blue}
              display='block'
              fontWeight='bold'
              wordBreak='break-all'
              href={`mailto:${email}`}
            >
              {email}
            </StyledLink>
          ))
        }
      </Box>
    </Flex>
  );
};

export const ContactInfoPair = (props: Object) => {
  if (G.isNilOrEmpty(R.prop(GC.FIELD_TEXT, props))) return null;

  const { text, phone, label, margin, lineHeight, iconMargin, autodialApp } = props;

  let href = `mailto:${text}`;

  if (G.isTrue(phone)) href = G.getCallUrl(autodialApp, text);

  return (
    <Flex
      py='4px'
      m={margin}
      fontSize={12}
      color={greyMatterhorn}
      lineHeight={R.or(lineHeight, 1.6)}
    >
      {G.isNotNilAndNotEmpty(label) && <Box>{label}: </Box>}
      <StyledLink
        ml='5px'
        href={href}
        color={blue}
        display='flex'
        fontWeight='bold'
        wordBreak='break-all'
      >
        {text}
        {
          phone &&
          <Box ml={R.or(iconMargin, '5px')}>
            {I.phone(blue, 10, 10)}
          </Box>
        }
      </StyledLink>
    </Flex>
  );
};

export const InfoPair = (props: Object) => {
  const {
    py,
    text,
    label,
    width,
    margin,
    flexWrap,
    fontSize,
    textColor,
    alignItems,
    fontWeight,
    lineHeight,
    marginRight,
    clickHandler,
    textMaxWidth,
    flexDirection,
    actionTextColor = null,
    additionalInfoWrapperStyles,
  } = props;

  if (G.isNilOrEmpty(text)) return null;

  const withHandler = G.isFunction(clickHandler);
  const color = G.ifElse(withHandler, blue, actionTextColor);

  return (
    <Flex
      m={margin}
      flexWrap={flexWrap}
      py={R.or(py, '4px')}
      wordBreak='break-all'
      width={R.or(width, '100%')}
      flexDirection={flexDirection}
      fontSize={R.or(fontSize, 12)}
      css={additionalInfoWrapperStyles}
      lineHeight={R.or(lineHeight, 1.6)}
      alignItems={alignItems || 'stretch'}
      color={R.or(textColor, greyMatterhorn)}
      cursor={G.ifElse(withHandler, 'pointer', 'unset')}
    >
      {label}:
      <Flex
        ml='5px'
        color={color}
        mr={marginRight}
        onClick={() => G.callFunction(clickHandler)}
      >
        <TextComponent
          title={text}
          withEllipsis={true}
          display='inline-block'
          maxWidth={R.or(textMaxWidth, 200)}
          fontWeight={R.or(fontWeight, 'bold')}
        >
          {text}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

export const InfoPairWithLabelAction = ({ text, label, action, labelActionText }: Object) => (
  <Flex
    py='4px'
    width='100%'
    fontSize={12}
    flexWrap='wrap'
    lineHeight={1.6}
    color={greyMatterhorn}
  >
    <Flex>
      {label}
      <Box
        ml='5px'
        color={blue}
        cursor='pointer'
        onClick={action}
        fontWeight='bold'
        wordBreak='break-all'
        textDecoration='underline'
      >
        {labelActionText}:
      </Box>
    </Flex>
    <Box ml='5px' fontWeight='bold'>{text}</Box>
  </Flex>
);

export const InfoPair2 = (props: Object) => {
  const {
    py,
    text,
    label,
    width,
    margin,
    flexWrap,
    fontSize,
    textColor,
    textWidth,
    alignItems,
    labelWidth,
    fontWeight,
    clickHandler,
    flexDirection,
    labelFontWeight,
    withoutSemicolon,
    actionTextColor = null,
    additionalInfoWrapperStyles,
  } = props;

  if (G.isNilOrEmpty(text)) return null;

  const withHandler = G.isFunction(clickHandler);
  const color = G.ifElse(withHandler, blue, actionTextColor);
  const textTitle = G.ifElse(isValidElement(text), '', text);

  return (
    <Flex
      m={margin}
      py={py || '4px'}
      flexWrap={flexWrap}
      wordBreak='break-word'
      width={width || '100%'}
      fontSize={fontSize || 12}
      flexDirection={flexDirection}
      css={additionalInfoWrapperStyles}
      color={textColor || greyMatterhorn}
      alignItems={alignItems || 'flex-start'}
      cursor={G.ifElse(withHandler, 'pointer', 'unset')}
    >
      <Box width={R.or(labelWidth, 80)} fontWeight={labelFontWeight || 400}>
        {label}{R.not(withoutSemicolon) && ':'}
      </Box>
      <Box
        ml='5px'
        color={color}
        title={textTitle}
        width={textWidth || 200}
        fontWeight={fontWeight || 'bold'}
        onClick={() => G.callFunction(clickHandler)}
      >
        {text}
      </Box>
    </Flex>
  );
};
