import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  Span,
  StyledLink,
  AbsoluteBox,
  RelativeBox,
  ActionButton,
} from '../../../ui';
// feature load-board
import * as H from '../helpers';
import { getUOMs } from '../settings/options';
import ShipmentDetails from './shipment-details';
import {
  makeSelectNotifications,
  makeSelectSearchFilterColors,
  makeSelectFilteredBySearchFilter,
  makeSelectIndexedSearchFilterList,
  makeSelectSearchResultsByFilterId,
} from '../selectors';
import {
  makeNotificationViewed,
  bookSavedShipmentRequest,
  setFilteredBySearchFilter,
  get123LoadBoardDetailsSuccess,
} from '../actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const ShipmentsNotificationsUI = ({
  data,
  filteredGuids,
  resultsByFilterId,
  setFilteredBySearchFilter,
}: Object) => {
  const { guid, color, savedShipmentCount } = data;

  const isFiltered = R.includes(guid, filteredGuids);
  const resultsByFilter = R.prop(guid, resultsByFilterId);
  const lightGrayColor = G.getTheme('colors.dark.lightGrey');
  const mainLightColor = G.getTheme('colors.light.mainLight');

  const filterColor = G.ifElse(
    isFiltered,
    blueColor,
    lightGrayColor,
  );

  return (
    <Flex>
      <Flex
        p='3px'
        mr='6px'
        bg={color}
        height={20}
        minWidth={20}
        borderRadius={2}
        border='1px solid'
        borderColor={color}
        width='fit-content'
        color={mainLightColor}
        justifyContent='center'
      >
        {savedShipmentCount}
      </Flex>
      {
        G.isNotEmpty(resultsByFilter) &&
        <RelativeBox
          cursor='pointer'
          width='fit-content'
        >
          {I.bellIcon()}
          <AbsoluteBox
            px='3px'
            height={14}
            bottom={-4}
            fontSize={9}
            minWidth={14}
            borderRadius='7px'
            border='1px solid'
            width='fit-content'
            color={mainLightColor}
            left='calc(100% - 6px)'
            justifyContent='center'
            borderColor={mainLightColor}
            bg={G.getTheme('icons.iconColor')}
          >
            {R.length(resultsByFilter)}
          </AbsoluteBox>
        </RelativeBox>
      }
      <Box
        ml='auto'
        cursor='pointer'
        data-testid='lb-table-cells-filter-action'
        onClick={() => setFilteredBySearchFilter(guid)}
      >
        {I.simpleFilter(filterColor, 12, 12)}
      </Box>
    </Flex>
  );
};

const mapStateToShipmentsNotificationsProps = (state: Object) => createStructuredSelector({
  notifications: makeSelectNotifications(state),
  filteredGuids: makeSelectFilteredBySearchFilter(state),
  searchFilters: makeSelectIndexedSearchFilterList(state),
  resultsByFilterId: makeSelectSearchResultsByFilterId(state),
});

export const ShipmentsNotifications = connect(mapStateToShipmentsNotificationsProps, {
  setFilteredBySearchFilter,
})(ShipmentsNotificationsUI);

const enhanceTimeFrom = compose(
  withState('timeFrom', 'setTimeFrom', ({ data }: Object) => H.getTimeFrom(data)),
  lifecycle({
    componentDidMount() {
      const { data, setTimeFrom } = this.props;

      this.interval = setInterval(() => setTimeFrom(H.getTimeFrom(data)), 60000);
    },
    componentWillUnmount() {
      clearInterval(this.interval);
    },
  }),
  pure,
);

export const TimeFrom = enhanceTimeFrom(({ timeFrom }: Object) => <Box title={`HH:mm - ${timeFrom}`}>{timeFrom}</Box>);

export const Email = ({ data, width, additionalText, additionalAction }: Object) => {
  const {
    origin,
    company,
    contact,
    destination,
    referenceId,
  } = data;

  const { email, contactName } = contact;

  const originText = H.getCityStateFromLocation(origin);
  const destinationText = H.getCityStateFromLocation(destination);
  const subject = `${R.propOr('', 'company', company)} - ${R.or(referenceId, '')} (${originText} - ${destinationText})`;
  const body = `Hi, ${contactName},%0D%0A%0D%0A
    Is this load still available? What is the best rate that you can do? I have a truck available.%0D%0A%0D%0A
    Best,`;

  const mailtoText = `${email}?subject=${subject}&body=${body}`;

  return (
    <StyledLink
      cursor='pointer'
      href={`mailto:${mailtoText}`}
    >
      <TextComponent
        color={blueColor}
        withEllipsis={true}
        title={contact.email}
        display='inline-block'
        onClick={additionalAction}
        width={R.or(width, '100%')}
      >
        {contact.email}
        {
          additionalText &&
          <Span ml='6px' color={G.getTheme('colors.black')}>{additionalText}</Span>
        }
      </TextComponent>
    </StyledLink>
  );
};

const enhanceDetails = compose(
  connect(null, {
    openModal,
    closeModal,
    makeNotificationViewed,
    get123LoadBoardDetailsSuccess,
  }),
  withHandlers({
    handleOpenShipmentDetails: ({
      data,
      openModal,
      makeNotificationViewed,
      get123LoadBoardDetailsSuccess,
    }: Object) => async ({ showMap, openedFromCell }: Object) => {
      const {
        type,
        shipment,
        highlighted,
        detailsLoaded,
      } = data;

      let dataToUse = data;

      if (highlighted) makeNotificationViewed(data);

      const lbTypesNeedDetails = [GC.EXTERNAL_LOAD_BOARD_LB123];

      if (R.and(R.includes(type, lbTypesNeedDetails), R.not(detailsLoaded))) {
        const id = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_ID], data);

        const details = await H.get123LBDetails(id);

        get123LoadBoardDetailsSuccess({ id, data: details });

        if (G.isNotNil(details)) {
          dataToUse = {
            ...data,
            detailsLoaded: true,
            shipment: R.mergeRight(shipment, details),
          };
        }
      }

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 1090, maxWidth: 'calc(100vw - 60px);' },
        component: (
          <ShipmentDetails data={dataToUse} showMap={showMap} openedFromCell={openedFromCell} />
        ),
        options: {
          minWidth: 500,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 1090, height: '100vh', maxWidth: 'calc(100vw - 60px);' },
          style: {
            borderLeft: '2px solid white',
            backgroundColor: G.getTheme('colors.bgGrey'),
          },
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const CompanyName = enhanceDetails(({
  data,
  handleOpenShipmentDetails,
}: Object) => {
  const company = R.path(
    [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY, GC.FIELD_LOAD_BOARD_COMPANY],
    data,
  );

  return (
    <TextComponent
      mr='8px'
      width='100%'
      height='100%'
      title={company}
      cursor='pointer'
      color={blueColor}
      withEllipsis={true}
      alignContent='center'
      data-testid='lb-table-cells-open-shipment-details-company'
      onClick={() => handleOpenShipmentDetails({ openedFromCell: GC.FIELD_LOAD_BOARD_COMPANY })}
    >
      {company}
    </TextComponent>
  );
});

export const PhoneNumberIdReference = enhanceDetails(({
  width,
  noIcon,
  displayText,
  openedFromCell,
  handleOpenShipmentDetails,
}: Object) => (
  <Flex
    width='100%'
    height='100%'
    cursor='pointer'
    data-testid='lb-table-cells-open-shipment-details-phone'
    onClick={() => handleOpenShipmentDetails({ openedFromCell })}
  >
    {R.not(noIcon) && I.phone()}
    <TextComponent
      color={blueColor}
      title={displayText}
      withEllipsis={true}
      display='inline-block'
      ml={G.ifElse(noIcon, '0', '8px')}
      width={R.or(width, 'fit-content')}
    >
      {displayText}
    </TextComponent>
  </Flex>
));

export const Contact = enhanceDetails(({ data, width, handleOpenShipmentDetails }: Object) => {
  const { shipment } = data;
  const { contact } = shipment;

  const name = R.propOr(null, GC.FIELD_CONTACT_NAME, contact);
  const email = R.propOr(null, GC.FIELD_LOAD_BOARD_EMAIL, contact);

  if (email) {
    return (
      <Email
        width={width}
        data={shipment}
        additionalText={name}
        additionalAction={handleOpenShipmentDetails}
      />
    );
  }

  const phone = R.propOr(null, GC.FIELD_LOAD_BOARD_PHONE, contact);
  const phoneExt = R.propOr(null, GC.FIELD_LOAD_BOARD_PHONE_EXT, contact);
  const phoneExtText = G.ifElse(G.isNotNilAndNotEmpty(phoneExt), `(${phoneExt})`, '');

  const phoneText = `${phone} ${phoneExtText}`;

  if (G.isNotNilAndNotEmpty(phone)) {
    return (
      <Flex
        width={width}
        height='100%'
        cursor='pointer'
        data-testid='lb-table-cells-open-details-distance'
        onClick={() => handleOpenShipmentDetails({ openedFromCell: GC.FIELD_LOAD_BOARD_PHONE })}
      >
        <TextComponent
          width='100%'
          color={blueColor}
          title={phoneText}
          withEllipsis={true}
          display='inline-block'
        >
          {phoneText}
          {
            name && <Span ml='6px' color={G.getTheme('colors.black')}>{name}</Span>
          }
        </TextComponent>
      </Flex>
    );
  }

  if (name) {
    return (
      <Flex
        width={width}
        height='100%'
        cursor='pointer'
        onClick={() => handleOpenShipmentDetails()}
      >
        <TextComponent
          title={name}
          width='100%'
          color={blueColor}
          withEllipsis={true}
          display='inline-block'
        >
          {name}
        </TextComponent>
      </Flex>
    );
  }

  return null;
});


export const Distance = ({ data, width }: Object) => {
  const shipment = R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data);

  return (
    <Box mr='6px' width={width} fontWeight='bold'>
      {R.path([GC.FIELD_LOAD_BOARD_DISTANCE_INFO, GC.FIELD_LOAD_BOARD_DISTANCE], shipment)}&nbsp;
      {getUOMs(R.path([GC.FIELD_LOAD_BOARD_DISTANCE_INFO, GC.FIELD_LOAD_BOARD_DISTANCE_UOM], shipment))}
    </Box>
  );
};

export const PickupDate = ({ data }: Object) => {
  const date = R.prop(GC.FIELD_LOAD_BOARD_PICKUP_DATE, data);

  if (G.isNotNil(date)) {
    return (
      <Box>
        {date}
      </Box>
    );
  }

  const frequency = R.prop(GC.FIELD_LOAD_BOARD_PICKUP_FREQUENCY, data);

  if (G.isNotNilAndNotEmpty(frequency)) {
    return (
      <Box>
        {G.getWindowLocale(R.prop(frequency, GC.EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_LOCALE_KEYS), frequency)}
      </Box>
    );
  }

  return null;
};

const filterColorEnhance = connect((state: Object) => createStructuredSelector({
  colors: makeSelectSearchFilterColors(state),
}), {});

export const FilterColorCell = filterColorEnhance(({ colors, searchFilterGuid }: Object) => (
  <Box
    width='6px'
    height='100%'
    bg={R.prop(searchFilterGuid, colors)}
  />
));

const actionBtnStyles = {
  p: 0,
  height: 20,
  fontSize: 14,
  minWidth: 50,
  borderRadius: '5px',
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
  bgColor: G.getTheme('colors.white'),
};

const BookingActionUI = ({
  data,
  currentUserData,
  bookSavedShipmentRequest,
}: Object) => {
  const type = R.prop(GC.FIELD_TYPE, data);
  const loadBoardsText = R.join(', ', R.map(H.getLBDisplayValue, GC.EXTERNAL_LOAD_BOARDS_TO_BOOK));

  const bookingMessage = `
    ${G.getWindowLocale('titles:booking-is-only-available', 'Booking process is only available for')}
    ${' '}${loadBoardsText}${' '}
    ${R.toLower(G.getWindowLocale('titles:load-boards', 'load boards'))}
  `.replace(/(\r\n|\r|\n|\t|(\s{4}))/g, '');

  const requiredFieldsMessage = H.checkRequiredBookingUserFields(type, currentUserData);

  const disabled = R.or(
    G.isNotNilAndNotEmpty(requiredFieldsMessage),
    R.not(R.includes(type, GC.EXTERNAL_LOAD_BOARDS_TO_BOOK)),
  );

  const title = G.ifElse(disabled, R.or(requiredFieldsMessage, bookingMessage), '');

  return (
    <Box title={title}>
      <ActionButton {...actionBtnStyles} disabled={disabled} onClick={() => bookSavedShipmentRequest(data)}>
        {G.getWindowLocale('actions:book', 'Book')}
      </ActionButton>
    </Box>
  );
};

export const BookingAction = connect((state: Object) => createStructuredSelector({
  currentUserData: makeSelectCurrentUserSettingsFields(state),
}), {
  bookSavedShipmentRequest,
})(BookingActionUI);

export const BookingStatus = ({
  data,
}: Object) => {
  const status = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_STATUS], data);
  const hasFailed = R.equals(GC.LB_STATUS_FAILED, status);

  const description = R.pathOr(
    '',
    [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_DESCRIPTION],
    data,
  );


  return (
    <Flex width='100%' justifyContent='space-between'>
      <Box>{G.getEnumLocale(status)}</Box>
      {
        hasFailed &&
        <Box title={description}>
          {I.warningIcon(G.getTheme('colors.black'), 14, 14, G.getTheme('colors.light.yellow'))}
        </Box>
      }
    </Flex>
  );
};

export const SeeDetails = enhanceDetails(({ handleOpenShipmentDetails }: Object) => (
  <Box
    cursor='pointer'
    color={blueColor}
    onClick={handleOpenShipmentDetails}
  >
    {G.getWindowLocale('actions:see-details', 'See details')}
  </Box>
));

export const Summary = ({ data, width }: Object) => {
  const {
    rate,
    rateCurrency,
  } = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_RATE], data);

  const {
    distance,
    distanceUom,
    originDeadhead,
    destinationDeadhead,
  } = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO], data);

  const showDistance = R.gt(distance, 0);
  const rateSymbol = G.getCurrencySymbol(rateCurrency);

  const totalDistance = G.ifElse(
    showDistance,
    R.add(distance, R.or(originDeadhead, 0)),
    null,
  );

  let rateCpm = null;
  let rateText = 'No rate';

  if (G.isNotNilAndNotEmpty(rate)) {
    rateText = `${rateSymbol} ${G.toFixed(rate)}`;

    if (showDistance) {
      rateCpm = G.toFixed(R.divide(rate, totalDistance));
      rateText = `${rateSymbol} ${G.toFixed(rate)} (${rateCpm})`;
    }
  }


  let distanceText = 'No distance info';
  let distanceTitle = distanceText;

  if (G.isNotNilAndNotEmpty(distance)) {
    distanceText = `${totalDistance} ${getUOMs(distanceUom)}`;
    distanceTitle = distanceText;

    if (R.or(G.isNotNilAndNotEmpty(originDeadhead), G.isNotNilAndNotEmpty(destinationDeadhead))) {
      distanceText = `${distanceText} (${R.or(originDeadhead, '')} ${
        destinationDeadhead ? '+' : ''} ${R.or(destinationDeadhead, '')})`;
      distanceTitle = `${G.getWindowLocale('titles:trip', 'Trip')}: ${distance}, ${
        G.getWindowLocale('titles:origin-deadhead', 'DH-O')}: ${
        R.or(originDeadhead, '-')}, ${G.getWindowLocale('titles:destination-deadhead', 'DH-D')}: ${
        R.or(destinationDeadhead, '-')}`;
    }
  }

  const title = `${rateText}\n${distanceTitle}`;

  return (
    <Box width={width} fontSize={10} title={title}>
      <TextComponent
        width='100%'
        fontWeight='bold'
        withEllipsis={true}
        display='inline-block'
      >
        {rateText}
      </TextComponent>
      <TextComponent
        width='100%'
        withEllipsis={true}
        display='inline-block'
      >
        {distanceText}
      </TextComponent>
    </Box>
  );
};
