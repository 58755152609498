import * as R from 'ramda';
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
} from 'react';
import { css } from 'styled-components';
import { VariableSizeList as List } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
// common
import {
  getReportTableResize,
  updateTableResizeByGuid,
  deleteTableResizeByGuid,
} from '../../common/idb/resize/actions';
import { makeSelectResizeTable } from '../../common/idb/resize/selectors';
// components
import { EmptyList } from '../list';
import ResizeActions from './components/resize-actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hooks
import useFixedPopover from '../../hooks/use-mui-fixed-popover';
// ui
import { Box, Flex, pulseOpacity } from '../../ui';
// groupable-table
import GroupableTableContext from './context';
import TableHeader from './components/table-header';
import { TableWrapper, TableScrollWrapper } from './ui';
import { GroupRow, GroupParentRow } from './components/table-body';
//////////////////////////////////////////////////

const darksGrey = G.getTheme('colors.light.darksGrey');
const lightGrey = G.getTheme('colors.dark.lightGrey');

const TableDimmer = (props: Object) => (
  <Box width='100%' animation={css`${pulseOpacity} 2s infinite linear;`}>
    {
      props.withHeader &&
      <Box
        width='100%'
        bg={darksGrey}
        height={props.tableSettings.titleRowHeight}
      />
    }
    {
      G.getComponentCountTimes(
        props.count,
        (_: undefined, i: number) => (
          <Box
            key={i}
            mt='2px'
            width='100%'
            bg={lightGrey}
            height={R.subtract(props.tableSettings.tableRowHeight, 2)}
          />
        )
        ,
      )
    }
  </Box>
);

const ItemWrapper = ({ data, index, style } : Object) => {
  const { ItemRenderer } = data;

  if (G.isZero(index)) {
    return null;
  }

  return <ItemRenderer index={index} style={style} />;
};

const innerElementType = forwardRef(({ children, style, ...rest } : Object, ref : Object) => (
  <GroupableTableContext.Consumer>
    {(props : Object) => {
      const { listData } = props;

      return (
        <div
          {...rest}
          ref={ref}
          style={{ ...style, position: 'relative', minWidth: '100%', width: 'fit-content' }}
        >
          <TableHeader {...props}/>
          {G.isNilOrEmpty(listData) && (
            <Flex width='100%' height={300} justifyContent='center'>
              <EmptyList>
                {G.getWindowLocale('titles:no-data-available', 'No Data Available')}
              </EmptyList>
            </Flex>
          )}
          {G.isNotNilAndNotEmpty(listData) && children}
        </div>
      );
    }}
  </GroupableTableContext.Consumer>
));

const TableList = ({
  loading,
  children,
  listData,
  filterProps,
  callbackData,
  rowTopMargin,
  resizeByGuid,
  expandedData,
  tableSettings,
  resizeObserver,
  setExpandedData,
  titleSortValues,
  tableTitleFilters,
  handleClickResetIcon,
  handleTableTitleFilter,
  ...rest
}: Object) => (
  <GroupableTableContext.Provider
    value={useMemo(() => ({
      loading,
      listData,
      filterProps,
      callbackData,
      resizeByGuid,
      rowTopMargin,
      expandedData,
      tableSettings,
      resizeObserver,
      setExpandedData,
      titleSortValues,
      tableTitleFilters,
      handleClickResetIcon,
      handleTableTitleFilter,
      ItemRenderer: children,
    }), [
      loading,
      children,
      listData,
      filterProps,
      callbackData,
      rowTopMargin,
      resizeByGuid,
      expandedData,
      tableSettings,
      resizeObserver,
      titleSortValues,
      setExpandedData,
      tableTitleFilters,
      handleClickResetIcon,
      handleTableTitleFilter,
    ])}
  >
    <List itemData={{ ItemRenderer: children }} {...rest}>
      {ItemWrapper}
    </List>
  </GroupableTableContext.Provider>
);

const TableRow = memo(({ index, style }: Object) => (
  <GroupableTableContext.Consumer>
    {(props: Object) => {
      const { listData, tableSettings, resizeByGuid } = props;

      const rowData = R.propOr({}, index, listData);
      const isParent = R.propOr(false, 'isParent', rowData);

      const { columnSettings } = tableSettings;

      const fields = R.keys(columnSettings);
      const parentColumnName = R.find((field: string) =>
        R.propEq(true, 'groupParent', R.prop(field, columnSettings)), fields);

      const parentColumn = R.prop(parentColumnName, columnSettings);
      const groupColumns = R.omit([parentColumnName], columnSettings);

      const parentWidth = R.prop('width', parentColumn);
      const rowIndent = R.propOr(parentWidth, parentColumnName, resizeByGuid);

      if (isParent) {
        return (
          <GroupParentRow
            {...props}
            style={style}
            data={rowData}
            parentColumn={parentColumn}
          />
        );
      }

      return (
        <GroupRow
          {...props}
          style={style}
          data={rowData}
          rowIndent={rowIndent}
          groupColumns={groupColumns}
          parentColumn={parentColumn}
        />
      );
    }}
  </GroupableTableContext.Consumer>
));

export const GroupableTable = (props: Object) => {
  const { data, loading, tableSettings } = props;

  const {
    report,
    groupByField,
    tableRowHeight,
    titleRowHeight,
    columnSettings,
    withResizableColumns,
  } = tableSettings;

  const [expandedData, setExpandedData] = useState([]);

  const listData = useMemo(() => {
    let innerData = ['header'];

    if (G.isNilOrEmpty(data)) return innerData;

    R.forEach((parentItem: Object) => {
      const name = R.prop(GC.FIELD_NAME, parentItem);

      const parent = {
        ...R.omit(['expanded', groupByField], parentItem),
        isParent: true,
        count: R.length(R.propOr([], groupByField, parentItem))
      };

      innerData.push(parent);

      if (R.includes(name, expandedData)) {
        innerData.push(...R.propOr([], groupByField, parentItem))
      }
    }, data);

    return innerData;
  }, [data, groupByField, expandedData]);

  const itemCount = R.or(R.length(listData), 1);

  const dispatch = useDispatch();

  const resizeTable = useSelector(makeSelectResizeTable());

  const guid = R.prop(GC.FIELD_GUID, report);
  const reportType = R.prop(GC.FIELD_TYPE, report);
  const isTableResizable = R.and(withResizableColumns, R.isNotNil(guid));
  const resizeByGuid = R.pathOr({}, [guid], resizeTable);

  const [resizeObserver, setResizeObserver] = useState(null);

  const { PopoverComponent, openFixedPopup, closeFixedPopup } = useFixedPopover();

  const handleClickResetByGuid = useCallback(() => {
    dispatch(deleteTableResizeByGuid(guid));
  }, [guid]);

  const handleClickResetIcon = useCallback(({ currentTarget }: Object) => {
    openFixedPopup({
      zIndex: 1300,
      position: 'right',
      el: currentTarget,
      content: (
        <ResizeActions
          closeFixedPopup={closeFixedPopup}
          reportType={R.prop(GC.FIELD_TYPE, report)}
          handleClickResetByGuid={handleClickResetByGuid}
        />
      ),
    });
  }, [report, openFixedPopup, closeFixedPopup, handleClickResetByGuid]);

  const handleUpdateTableResize = useCallback((resizedTableField: Object) => {
    dispatch(updateTableResizeByGuid({
      reportType,
      reportGuid: guid,
      resizedTableField,
    }));
  }, [guid, reportType]);

  const createResizeObserver = useCallback(() => {
    // eslint-disable-next-line no-undef
    const observerInstance = new ResizeObserver(
      G.setDebounce((entries: Object) => {
        if (R.gt(R.prop('length', entries), 1)) return;

        if (R.pathOr(false, [0, 'borderBoxSize'], entries)) {
          const target = entries[0].target;
          const newWidth = R.pathOr(null, [0, 'borderBoxSize', 0, 'inlineSize'], entries);
          const fieldName = G.getPropFromObject(GC.FIELD_NAME, target);
          const storedWidth = R.prop(fieldName, resizeByGuid);
          const width = R.path([fieldName, 'width'], columnSettings);

          if (R.or(
            R.or(
              R.equals(newWidth, 0),
              R.equals(storedWidth, newWidth),
            ),
            R.and(
              R.isNil(storedWidth),
              R.equals(width, newWidth),
            ),
          )) return;

          handleUpdateTableResize({fieldName, width: newWidth});
        }
      }, 200),
    );

    setResizeObserver(observerInstance);
  }, [resizeByGuid, columnSettings, handleUpdateTableResize]);

  const getItemSize = useCallback((index: number) => G.ifElse(G.isZero(index), titleRowHeight, tableRowHeight), []);

  useEffect(() => {
    if (R.and(isTableResizable, R.not(loading))) {
      dispatch(getReportTableResize(guid));
      createResizeObserver();
    }

    return () => {
      if (isTableResizable) {
        resizeObserver?.disconnect();
      }
    }
  }, [guid, loading]);

  return (<>
    <TableWrapper>
      {R.not(loading) && (
        <TableScrollWrapper>
          <TableList
            {...props}
            width='100%'
            height={425}
            listData={listData}
            itemCount={itemCount}
            itemSize={getItemSize}
            expandedData={expandedData}
            resizeByGuid={resizeByGuid}
            setExpandedData={setExpandedData}
            tableSettings={tableSettings}
            resizeObserver={resizeObserver}
            innerElementType={innerElementType}
            handleClickResetIcon={handleClickResetIcon}
          >
            {TableRow}
          </TableList>
        </TableScrollWrapper>
      )}
      {loading && (
        <TableDimmer
          count={9}
          withHeader={true}
          tableSettings={tableSettings}
        />
      )}
    </TableWrapper>
    {PopoverComponent}
  </>);
};
